import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import mainLogo from '../Assets/Images/main_logo.png'
import smallLogo from '../Assets/Images/logo.png'
import menuIcon from '../Assets/Icons/menu.svg'

const DashboardHeader = ({ handleSidebar }) => {
  const { currentUser } = useSelector(state => state.currentUser)

  return (
    <div className='sticky top-0 bg-white px-4 md:px-20 h-16 flex items-center justify-between shadow-lg z-10'>
      <div className='flex items-center'>
        <img src={ menuIcon } alt='menu-icon' className='mr-12 cursor-pointer' onClick={handleSidebar}/>
        <Link to='/'>
          <img src={ mainLogo } alt="logo" className='hidden sm:flex w-40 cursor-pointer' />
          <img src={ smallLogo } alt='small-logo' className='sm:hidden w-40 cursor-pointer' />
        </Link>
      </div>
      <div className='flex items-center'>
        <div className='w-[40px] h-[40px] rounded-full bg-headers flex items-center justify-center ml-4 cursor-pointer'>
          {currentUser?.userPicture?
            <img src={ currentUser?.userPicture } alt="Client-Img" className="w-full h-full rounded-full object-cover" />:
            <p className='text-white font-bold text-2xl'>{currentUser?.firstName?.slice(0,2).toUpperCase()}</p>}
        </div>
        <div className='ml-2 hidden sm:flex flex-col'>
          <h6 className='text-xl text-headers font-bold'>{currentUser?.firstName} {currentUser?.lastName}</h6>
          <span className='text-text'>Administrateur</span>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader