import DashboardIcon from '../Assets/Icons/house.svg'
import UsersIcon from '../Assets/Icons/users.svg'
import UserIcon from '../Assets/Icons/user.svg'
import SubscribersIcon from '../Assets/Icons/subscribers.svg'
import ReviewIcon from '../Assets/Icons/comment.svg'
import MessageIcon from '../Assets/Icons/envelope.svg'

const sidebar_menu = [
  {
    id: 1,
    icon: DashboardIcon,
    path: '/dashboard',
    title: 'DashBoard',
  },
  {
    id: 2,
    icon: UsersIcon,
    path: '/dashboard/users',
    title: 'Users',
  },
  {
    id: 3,
    icon: ReviewIcon,
    path: '/dashboard/reviews',
    title: 'Reviews',
  },
  {
    id: 4,
    icon: MessageIcon,
    path: '/dashboard/messages',
    title: 'Messages',
  },
  {
    id: 5,
    icon: SubscribersIcon,
    path: '/dashboard/subscribers',
    title: 'Subscribers',
  },
  {
    id: 6,
    icon: UserIcon,
    path: '/dashboard/profile',
    title: 'Profile',
  }
]

export default sidebar_menu