import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { userLogout } from './Redux/ApiCalls'
import AuthService from './Components/AuthService'
import Navbar from './Components/Navbar'
import DashboardHeader from './Components/DashboardHeader'
import SideBar from './Components/SideBar'
import ScrollToTop from './Components/ScrollToTop'
import Home from './Pages/Home'
import About from './Pages/About'
import Blog from './Pages/Blog'
import Carrier from './Pages/Carrier'
import Contacts from './Pages/Contacts'
import Projects from './Pages/Projects'
import Services from './Pages/Services'
import CookiesPolicy from './Pages/CookiesPolicy'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import TermsAndConditions from './Pages/TermsAndConditions'
import SingleService from './Pages/SingleService'
import Login from './Pages/Login'
import Registration from './Pages/Registration'
import ForgetPassword from './Pages/ForgetPassword'
import ResetPassword from './Pages/ResetPassword'
import ResetUserPassword from './Pages/ResetUserPassword'
import RegistrationConfirmation from './Pages/RegistrationConfirmation'
import SendEmailVerification from './Pages/SendEmailVerification'
import Reviews from './Pages/Reviews'
import CommingSoon from './Pages/CommingSoon'
import Footer from './Components/Footer'

import Dashboard from './Admin/Dashboard'
import DashboardMessages from './Admin/DashboardMessages'
import DashboardReviews from './Admin/DashboardReviews'
import DashboardSubscribers from './Admin/DashboardSubscribers'
import DashboardUsers from './Admin/DashboardUsers'
import DashboardProfile from './Admin/DashboardProfile'

import CookiesPopUp from './Components/cookiesPopUp'


const App = () => {
  const dispatch = useDispatch()
  const [showCookiesPopup, setShowCookiesPopup] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(true)
  const { currentUser } = useSelector(state => state?.currentUser)

  const handleSidebar = () => {
    setToggleSidebar(!toggleSidebar)
  }

  const handleAcceptCookies = () => {
    localStorage.setItem('thrypesCookieConsent', 'true')
    setShowCookiesPopup(false)
  }

  useEffect(() => {
    const hasConsent = localStorage.getItem('thrypesCookieConsent')
    setShowCookiesPopup(!hasConsent)
  }, [])

  useEffect(() => {
    if (AuthService.isTokenExpired()) {
      userLogout(dispatch)
    }
  }, [dispatch])

  return (
    <div className="font-Abel">
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={
            <div>
              <ScrollToTop />
              <Navbar />
              <div className="min-h-[60vh]">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/carrier" element={<Carrier />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/projects" element={<Projects />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/services/:service" element={<SingleService />} />
                  <Route path="/cookiesPolicy" element={<CookiesPolicy />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                  <Route path="/forgetPassword" element={<ForgetPassword />} />
                  <Route path="/resetUserPassword" element={!currentUser ? <Navigate to="/" /> : <ResetUserPassword />} />
                  <Route path="/reviews" element={<Reviews />} />
                  <Route exact path="/login" element={!currentUser ? <Login /> : <Navigate to="/reviews" />} />
                  <Route exact path="/registration" element={!currentUser ? <Registration /> : <Navigate to="/" />} />
                  <Route exact path="/registrationConfirmation" element={!currentUser ? <RegistrationConfirmation /> : <Navigate to="/" />} />
                  <Route path="*" element={<p className='text-center text-4xl font-bold pt-48'>There is nothing here: 404!</p>} />
                </Routes>
              </div>
              <Footer />
              {showCookiesPopup && (<CookiesPopUp handleAcceptCookies={handleAcceptCookies} />)}
            </div>}
          />
          <Route path="/skyscapr" element={<CommingSoon />} />
          <Route path="/reset/password/:token" element={<ResetPassword />} />
          <Route path="/verify/email/:token" element={<SendEmailVerification />} />
          <Route path="/dashboard/*" element={currentUser?.userType === false ? (
            <div>
              <DashboardHeader handleSidebar={handleSidebar} />
              <div className='flex'>
                {toggleSidebar && <SideBar handleSidebar={handleSidebar} />}
                <div className='flex-auto max-w-[100vw]'>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/users" element={<DashboardUsers />} />
                    <Route path="/reviews" element={<DashboardReviews />} />
                    <Route path="/messages" element={<DashboardMessages />} />
                    <Route path="/subscribers" element={<DashboardSubscribers />} />
                    <Route path="/profile" element={<DashboardProfile />} />
                  </Routes>
                </div>
              </div>
            </div>
          ) : <Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
