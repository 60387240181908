import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'

import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import eye from '../Assets/Icons/eye.svg'
import eyeCrossed from '../Assets/Icons/eye-crossed.svg'
import { apiRequest } from '../Redux/ApiCalls'
import { passwordValidation } from '../Helpers/helpers'

const Registration = () => {
  const navigate = useNavigate()
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState('password')
  const [showConfirmPassword, setShowConfirmPassword] = useState('password')
  const [inputs,setInputs] = useState({
    firstName:'',
    lastName:'',
    email:'',
    password:'',
    confirmPassword:'',
  })

  const { firstName,lastName,email,password,confirmPassword } = inputs

  const handleChange = (e) => {
    setInputs({ ...inputs,[e.target.name]:e.target.value })
  }

  const togglePassword = () => {
    showPassword==='password'?
      setShowPassword('text') :
      setShowPassword('password')
  }

  const toggleConfirmPassword = () => {
    showConfirmPassword==='password'?
      setShowConfirmPassword('text') :
      setShowConfirmPassword('password')
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const isValidPassword = passwordValidation(password)
    setIsLoading(true)
    if(!firstName || !lastName || !email || !password || !confirmPassword) {
      setFailureMessage('All fields are required')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else if (!isValidPassword) {
      setFailureMessage('Password should be at least 8 characters long and contain at least one special character, one number, and one uppercase letter.')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 10000)
    } else if (password !== confirmPassword) {
      setFailureMessage('Confirm password must be the same as password')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else {
      try {
        const res = await apiRequest.post('/users',inputs,{ timeout: 30000 })
        setIsLoading(false)
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 5000)
        setInputs({
          firstname:'',
          lastname:'',
          email:'',
          password:'',
          confirmPassword:'',
        })
        setTimeout(() => {
          navigate('/registrationConfirmation')
        }, 5000)
      } catch (error) {
        if (error.response) {
          setFailureMessage(error.response?.data.message)
        } else {
          setFailureMessage(error.message)
        }
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    }
  }

  return (
    <div className='px-4 md:px-20 py-8'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <h2 className='mb-8 text-4xl font-bold text-center'>Sign Up</h2>
      <div className="flex items-center justify-center">
        <form className='w-full md:w-2/3 lg:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
          <div className='flex flex-col w-full m-1'>
            <label htmlFor="firstName" className='mb-1 text-lg font-bold'>Firstname*</label>
            <input type='text' name='firstName' placeholder='John' maxLength='50'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full m-1'>
            <label htmlFor="lastName" className='mb-1 text-lg font-bold'>Lastname*</label>
            <input type='text' name='lastName' placeholder='Doe' maxLength='50'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Email*</label>
            <input type='email' name='email' placeholder='johndoe@gmail.com' maxLength='50'
              className='p-2 border rounded-lg' onChange={handleChange} />
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Password*</label>
            <div className="flex items-center border rounded-lg">
              <input type={showPassword} name='password' placeholder='Password' maxLength='50'
                className='p-2 w-full rounded-lg' onChange={handleChange} />
              <div data-testid='toggle-password-button' onClick={togglePassword} className='p-2 cursor-pointer'>
                {showPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Confirm Password*</label>
            <div className="flex items-center border rounded-lg">
              <input type={showConfirmPassword} name='confirmPassword' placeholder='Confirm Password' maxLength='50'
                className='p-2 w-full rounded-lg' onChange={handleChange} />
              <div data-testid='toggle-confirm-password-button' onClick={toggleConfirmPassword} className='p-2 cursor-pointer'>
                {showConfirmPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
              </div>
            </div>
          </div>
          <div className='my-2'>
            <input data-testid='checkbox' type="checkbox" className='mr-1 accent-strong' required />
            <label htmlFor="agreeTerms">
              I agree to the<Link to='/termsAndConditions'>
                <span className='text-headers font-bold ml-1'>Terms and Conditions</span>.
              </Link>
            </label>
          </div>
          <button data-testid='Sign-Up' type='submit' className='flex items-center justify-start px-4 py-2 text-md text-white bg-headers rounded-lg
          font-semibold shadow-sm' disabled={isLoading}>
            {isLoading && <div className="w-full"><Loading color={'white'} /></div>}
            <div className='ml-2'>{isLoading? 'Signing...' : 'Sign Up'}</div>
          </button>
          <p className='my-2'>Do you have an account?
            <Link to='/login'><span className='text-headers font-bold ml-1'>Sign In</span></Link>
          </p>
        </form>
      </div>
    </div>
  )
}

export default Registration