import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Sercives } from '../Constants/Services'

import checkmarkImage from '../Assets/Icons/check.svg'

const SingleService = () => {
  const location = useLocation()
  const serviceName = location.pathname.split('/')[2].split('%20').join(' ')
  const service = Sercives.find(service => service.title === serviceName)
  const leftService = Sercives.filter(service => service.title !== serviceName)

  return (
    <div>
      <div className='relative'>
        <img src={service.image} alt={service.title} className='max-h-[50vh] w-full object-cover brightness-50' />
        <h2 className='absolute bottom-8 left-4 md:left-20 text-white text-3xl md:text-5xl lg:text-7xl font-bold'>{service.title}</h2>
      </div>
      <div className='px-4 md:px-20 py-8'>
        {/* <p className='text-text md:text-xl md:w-2/3 lg:w-1/2'>{service.description}</p>
        <p className='my-4 text-sm md:text-md'>Our {service.title} includes:</p> */}
        <div className='flex items-center justify-center flex-wrap'>
          {service.products.map(product =>
            <div key={product.id} className='w-[350px] max-h-[450px] shadow-xl m-2 p-4 sm:p-6 lg:m-8 bg-white rounded-lg ease-in-out duration-300 hover:translate-y-2'>
              <img src={product.image} alt={product.name} className='h-[100px] lg:h-[140px] object-contain mx-auto' />
              <h2 className='my-2 text-headers text-md lg:text-xl font-bold text-center'>{product.name}</h2>
              <p className='text-text text-justify text-sm md:text-md'>{product.description}</p>
              <p>Key Services:</p>
              <ul className='my-2 list-none'>
                {product.subProducts.map((item,index) =>
                  <li key={index} className='flex items-center md:ml-4 text' >
                    <img src={checkmarkImage} alt="Checkmark" className="mr-2" />
                    <span>{item}</span>
                  </li>)}
              </ul>
              {/* <div className='flex flex-wrap my-4 items-center justify-center'>
                {product.options?.map(option =>
                  <div key={option.id} className='m-2 max-w-[200px] md:max-w-[250px] h-[200px] md:h-[250px] rounded-lg shadow-lg p-2'>
                    <img src={option.illustration} alt='optionIcon' className='w-full h-2/3' />
                    <h2 className='text-center text-md md:text-xl font-bold text-headers mt-1 md:mt-4'>{option.title}</h2>
                  </div>
                )}
              </div> */}
            </div>
          )}
        </div>
        <div className='mt-16'>
          <h3 className='text-text text-xl font-bold'>Other related Services:</h3>
          <ul className='mt-4 list-disc ml-8 flex flex-col'>
            {leftService.map(service =>
              <li key={service.id}>
                <Link to={`/services/${service.title}`}
                  className='text-xl cursor-pointer underline text-headers font-bold'>
                  {service.title}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SingleService