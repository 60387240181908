import React from 'react'
import airplane from '../Assets/Images/airplane.png'

const CommingSoon = () => {
  return (
    <div className="h-screen w-screen bg-white flex justify-center items-center">
      <div className='w-full flex flex-col items-center justify-center px-4'>
        <a href='/' className='px-4 py-2 rounded-lg text-2xl font-extrabold mb-16 bg-gradient-to-r from-sky-500 to-blue-500 hover:from-cyan-500 hover:to-indigo-500'>
          Go Back
        </a>
        <h1 className="text-center text-4xl md:text-7xl lg:text-9xl font-bold text-gray-600 font-black md:tracking-widest mb-4 animate-bounce">
          COMING SOON.
        </h1>
        <img
          src={airplane}
          alt="Background"
        />
        <h1 className='text-center text-5xl md:text-8xl font-extrabold text-[#29ace3]'>SKYSCAPR</h1>
        <p className="text-2xl text-gray-600 mt-4 tracking-wide text-center">
          We will be celebrating the launch of our new site very soon!
        </p>
      </div>
    </div>
  )
}

export default CommingSoon