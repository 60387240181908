import React,{ useState } from 'react'
import { apiRequest } from '../Redux/ApiCalls'

import Notification from '../Components/Notification'
import Loading from '../Components/Loading'

const ForgetPassword = () => {
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [isLoading,setIsLoading] = useState(false)
  const [email,setEmail] = useState('')

  const handleSubmit = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    if(email === '') {
      setFailureMessage('Email Field is required')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else {
      try {
        const res = await apiRequest.post('/users/forgot/password',{ email },{ timeout: 30000 })
        setIsLoading(false)
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 10000)
        setEmail('')
      } catch (error) {
        if (error.response) {
          setFailureMessage(error.response?.data.message)
        } else {
          setFailureMessage(error.message)
        }
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    }
  }

  return (
    <div className='px-4 md:px-20 py-32'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <h2 className='mb-8 text-4xl font-bold text-center'>Forgot Password</h2>
      <div className="flex items-center justify-center">
        <form className='w-full md:w-2/3 lg:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
          <div className='flex flex-col w-full mt-2 mb-4'>
            <label htmlFor="email" className='mb-1 text-lg font-bold'>Email*</label>
            <input type='email' name='email' value={email} placeholder='johndoe@gmail.com' maxLength='50'
              className='p-2 border rounded-lg' onChange={(e) => setEmail(e.target.value)} />
          </div>
          <button data-testid="forgotPassword" type='submit' className='flex items-center justify-start px-4 py-2 text-md text-white bg-headers rounded-lg
          font-semibold shadow-sm' disabled={isLoading}>
            {isLoading && <div className="w-full mr-2"><Loading color={'white'} /></div>}
            {isLoading? 'Sending...' : 'Forgot Password'}</button>
        </form>
      </div>
    </div>
  )
}

export default ForgetPassword