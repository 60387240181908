import { createSlice } from '@reduxjs/toolkit'

const usersSlice = createSlice({
  name:'users',
  initialState:{
    users: [],
    isFetching: false,
    error: null,
  },
  reducers:{
    // GET ALL USERS
    getUsersStart:(state) => {
      state.isFetching=true
    },
    getUsersSuccess:(state,action) => {
      state.isFetching=false
      state.users=action.payload
      state.error=null
    },
    getUsersFailure:(state,error) => {
      state.isFetching=false
      state.error=error.payload
    },
    // SEARCH USERS BY NAME
    searchUsersByNameStart:(state) => {
      state.isFetching=true
    },
    searchUsersByNameSuccess:(state,action) => {
      state.isFetching=false
      state.users=action.payload
      state.error=null
    },
    searchUsersByNameFailure:(state,error) => {
      state.isFetching=false
      state.error=error.payload
    },
    // DELETE A USER
    deleteUserStart:(state) => {
      state.isFetching=true
      state.error=null
    },
    deleteUserSuccess:(state,action) => {
      state.isFetching=false
      state.users.splice(state.users.findIndex((item) => item.data.userUid === action.payload.id),1)
      state.error=null
    },
    deleteUserFailure:(state,error) => {
      state.isFetching=false
      state.error=error.payload
    },
    // UPDATE A USER
    updateUserStart:(state) => {
      state.isFetching=true
      state.error=null
      state.success=null
    },
    updateUserSuccess:(state,action) => {
      state.isFetching=false
      state.users[state.users.findIndex((item) => item.data.userUid === action.payload.id)].data=action.payload.updatedUser
      state.error=null
    },
    updateUserFailure:(state,error) => {
      state.isFetching=false
      state.error=error.payload
      state.success=null
    },
  }
})


export const { getUsersStart,getUsersSuccess,getUsersFailure,searchUsersByNameStart,
  searchUsersByNameSuccess,searchUsersByNameFailure,updateUserStart,updateUserSuccess,
  updateUserFailure,deleteUserStart,deleteUserSuccess,deleteUserFailure } = usersSlice.actions
export default usersSlice.reducer