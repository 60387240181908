import React from 'react'
import { Link } from 'react-router-dom'

const cookiesPopUp = ({ handleAcceptCookies }) => {
  return (
    <div className="z-50 fixed bottom-4 left-4 font-bold max-w-xs md:max-w-md p-4 text-white bg-strong border border-gray-300 rounded shadow-lg">
      <h3 className='mb-4 text-3xl text-black'>Cookie Notice</h3>
      <p className="mb-2 text-xl">This website uses cookies to ensure you get the best user experience. By using our website, you agree to our use of cookies. You can learn more about our use of cookies in our
        <Link to='/cookiesPolicy'><span className='text-headers font-bold ml-1'>Cookie Policy</span>.</Link>
      </p>
      <button className="bg-headers text-white px-4 py-2 m-2 rounded hover:bg-extention hover:text-black" onClick={handleAcceptCookies}>
        Accept Cookies
      </button>
    </div>
  )
}

export default cookiesPopUp