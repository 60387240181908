import React,{ useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import { apiRequest } from '../Redux/ApiCalls'
import logo from '../Assets/Images/second_logo.png'

const SendEmailVerification = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading,setIsLoading] = useState(false)
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const token = location.pathname.split('/')[3]
  const headers = {
    token: token,
  }
  const sendEmailVerification = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await apiRequest.put('/users/verify/email', {}, { headers }, { timeout: 30000 })
      setIsLoading(false)
      setSuccessMessage(res.data.message)
      setTimeout(() => {
        setSuccessMessage(null)
      }, 5000)
      setTimeout(() => {
        navigate('/login')
      }, 5000)
    } catch (error) {
      if (error.response) {
        setFailureMessage(error.response?.data.message)
      } else {
        setFailureMessage(error.message)
      }
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    }
  }

  return (
    <div className='w-screen h-screen px-4 md:px-20 flex items-center justify-center'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <Link to='/'>
        <div className=' absolute top-12 left-20 w-[200px]'>
          <img src={logo} alt='Icon-logo' />
        </div>
      </Link>
      <div className="w-full">
        <h2 className='mb-8 md:mb-16 text-4xl md:text-6xl font-bold text-center'>Verify Email</h2>
        <p className='mb-8 text-lg md:text-xl text-center text-text'>
          To complete your registration, please click on the button below to confirm the email verification.
        </p>
        <div className="flex items-center justify-center">
          <button
            data-testid='verify email'
            type='submit'
            className='flex items-center justify-start px-4 md:px-8 py-2 md:py-4 text-xl md:text-2xl text-white
              bg-headers rounded-lg font-semibold shadow-sm'
            onClick={sendEmailVerification}>
            {isLoading && <div className="w-full mr-2"><Loading color={'white'} /></div>}
            {isLoading? 'Sending...' : 'Send Email Verification'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SendEmailVerification