import React from 'react'
import { Link } from 'react-router-dom'

import { Sercives } from '../Constants/Services'
import secondaryLogo from '../Assets/Images/second_logo.png'
import facebook from '../Assets/Icons/facebook.svg'
import linkedin from '../Assets/Icons/linkedin.svg'
import twitter from '../Assets/Icons/twitter.svg'

const Footer = () => {
  const year = (new Date()).getFullYear()

  return (
    <div className='px-4 md:px-20'>
      <hr className="border-gray-300" />
      <div className='md:flex items-start justify-between py-8'>
        <div className='md:basis-1/3 flex flex-col items-center md:items-start'>
          <div className='w-40'>
            <img src={secondaryLogo} alt='secondary-logo' className='w-full' />
          </div>
          <p className='text-text w-[60%] my-2 text-center md:text-left'>Chart the path for creating innovative, seamless and simple solutions.</p>
          <div className='flex items-center'>
            <Link to=''>
              <div className='m-1'>
                <img src={facebook} alt='facebook logo' className=''/>
              </div>
            </Link>
            <Link to=''>
              <div className='m-2 bg-[#45D239] rounded-full'>
                <img src={twitter} alt='twitter logo' className='p-[3px]'/>
              </div>
            </Link>
            <Link to=''>
              <div className='m-1'>
                <img src={linkedin} alt='linkedin logo' className=''/>
              </div>
            </Link>
          </div>
        </div>
        <div className='md:basis-2/3 md:flex items-start justify-between'>
          <div className='md:basis-1/2 flex justify-between sm:justify-around pt-8'>
            <div className='md:flex-1'>
              <h3 className='text-lg font-bold mb-4'>What We Do</h3>
              <div>
                {Sercives.map(service =>
                  <Link to={service.title === 'Mobility and Travels'? '/skyscapr' : `/services/${service.title}`} key={service.id}>
                    <p className='hover:text-headers text-text'>{service.title}</p>
                  </Link>
                )}
              </div>
            </div>
            <div className='md:flex-1'>
              <h3 className='text-lg font-bold mb-4'>Company</h3>
              <div>
                <Link to='/about'>
                  <p className='hover:text-headers text-text'>About Us</p>
                </Link>
                <Link to='/blog'>
                  <p className='hover:text-headers text-text'>Blog</p>
                </Link>
                <Link to='/carrier'>
                  <p className='hover:text-headers text-text'>Career</p>
                </Link>
                <Link to='/services'>
                  <p className='hover:text-headers text-text'>Services</p>
                </Link>
                <Link to='/reviews'>
                  <p className='hover:text-headers text-text'>Reviews</p>
                </Link>
                <Link to='/forgetPassword'>
                  <p className='hover:text-headers text-text'>Reset Password</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='md:basis-1/2 flex items-start justify-between sm:justify-around pt-8'>
            <div className='md:flex-1'>
              <h3 className='text-lg font-bold mb-4'>Legal</h3>
              <div>
                <Link to='/privacyPolicy'>
                  <p className='hover:text-headers text-text'>Privacy Policy</p>
                </Link>
                <Link to='/cookiesPolicy'>
                  <p className='hover:text-headers text-text'>Cookies Policy</p>
                </Link>
                <Link to='/termsAndConditions'>
                  <p className='hover:text-headers text-text'>Terms and Conditions</p>
                </Link>
              </div>
            </div>
            <div className='md:flex-1'>
              <h3 className='text-lg font-bold mb-4'>Contact</h3>
              <div>
                <Link to='/contacts'>
                  <p className='hover:text-headers text-text'>Contact us</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-gray-100" />
      <p className='text-center p-4 text-text'>Copyright © {year} Thrypes</p>
    </div>
  )
}

export default Footer