import React from 'react'
// import arrowDown from '../Assets/Icons/arrow-down.svg'
// import arrowUp from '../Assets/Icons/arrow-up.svg'

const Card = ({ item,number }) => {
  const { title,Icon,color,background } = item

  return (
    <div className='card p-4 lg:p-8 m-2 shadow-lg rounded-lg min-w-[200] lg:min-w-[350px]'>
      <div className='text-xl md:text-3xl font-bold'>
        <h2 style={{ color }}>{title}</h2>
      </div>
      <div className='flex items-center justify-start my-2'>
        <div className="basis-1/2">
          <div className='w-[80px] h-[80px] rounded-full flex items-center justify-center' style={{ background }}>
            <Icon fill={color} />
          </div>
        </div>
        <div className='basis-1/2'>
          <h2 className='text-4xl md:text-7xl font-bold' style={{ color }}>{number}</h2>
          {/* <div className='flex items-center justify-center'>
              <span className='mr-2' style={{color}}>{(number/lastNumber).toFixed(2)}%</span>
              <div className='arrow-container'>
                {(number/lastNumber).toFixed(2) > 1 ?
                <span className='text-[#044404] flex items-center'>
                  <img src={arrowUp} alt='arrow-up-Icon' className='' />
                  <span>Increase</span>
                </span> :
                <span className='text-[#ff0000] flex items-center'>
                  <img src={arrowDown} alt='arrow-down-Icon' className='' />
                  <span>Decrease</span>
                </span>}
                </div>
            </div> */}
        </div>
      </div>
      {/* <span className='text-xl text-text'>{description}</span> */}
    </div>
  )
}

export default Card