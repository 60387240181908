import React from 'react'
import cancelIcon from '../Assets/Icons/cross.svg'

const PopUpModal = ({ date, title, message, name, onConfirm, onCancel, confirm }) => {
  return (
    <div className='h-screen w-screen fixed bottom-0 left-0 right-0 top-0 z-50'>
      <div className='h-screen w-screen fixed top-0 bottom-0 left-0 right-0 bg-black/[0.9]'></div>
      <div className="absolute top-32 left-0 right-0 mx-auto w-5/6 md:w-1/2 xl:w-1/3 max-h-[50%] rounded bg-white text-black p-8">
        <div className='ralative w-full h-full flex flex-col align-center justify-start'>
          <p className='font-bold text-text'>{date}</p>
          <h2 className='text-3xl text-headers text-center font-[800] mb-2'>{confirm}</h2>
          {name && <h3 className='text-lg text-headers font-[600] mb-2'><span className='text-xl text-text'>From: </span>{name}</h3>}
          {title && <h2 className='text-xl text-headers font-[800] mb-8'><span className='text-2xl text-text'>Subject: </span>{title}</h2>}
          <p className={`text-xl text-text text-${confirm? 'center': 'justify'} mb-8 break-words`}>{message}</p>
          {confirm ?
            <div className="flex items-center justify-between">
              <button className='px-4 py-2 text-lg bg-headers text-white font-bold rounded-lg' onClick={onConfirm} >Confirm</button>
              <button className='px-4 py-2 text-lg bg-red-700 text-white font-bold rounded-lg' onClick={onCancel} >Cancel</button>
            </div> :
            <button data-testid='close' className='absolute top-2 right-2 p-2 bg-red-700 rounded-full' onClick={onCancel}>
              <img src={cancelIcon} alt='cancel-Icon' />
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default PopUpModal