import React, { useEffect, useState } from 'react'
import { useLocation,useNavigate,Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import menu from '../Constants/sideBar_Menu'
import { userLogout } from '../Redux/ApiCalls'
import LogoutIcon from '../Assets/Icons/logout.svg'

function SideBar ({ handleSidebar }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigateUrl = useNavigate()
  const [active, setActive] = useState(1)

  useEffect(() => {
    menu.forEach(element => {
      if (location.pathname === element.path) {
        setActive(element.id)
      }
    })
  }, [location.pathname])

  const navigate = (id) => {
    setActive(id)
    if (window.innerWidth<1050){
      handleSidebar()
    }
  }

  const logOut = () => {
    userLogout(dispatch)
    navigateUrl('/')
  }

  return(
    <nav className='bg-white text-headers fixed lg:sticky top-[7vh] shadow-lg h-[93vh] min-w-[200px] md:min-w-[270px] p-8 z-10'>
      <div className='relative h-full'>
        <div>
          {menu.map((item, index) => (
            <div key={index} onClick={() => navigate(item.id)}>
              <Link
                to={item.path}
                className={`flex items-center my-2 p-2 hover:bg-gray-100 rounded-lg ${item.id === active ? 'bg-gray-200' : ''}`}>
                <img
                  src={item.icon}
                  alt={`icon-${item.icon}`}
                  className='w-4 md:w-8' />
                <span className='font-bold text-lg md:text-2xl ml-4'>{item.title}</span>
              </Link>
            </div>
          ))}
        </div>
        <div data-testid='Logout' className='flex items-center justify-between rounded-lg absolute bottom-8 p-2 md:p-4 bg-red-400 w-full cursor-pointer' onClick={logOut}>
          <span className='text-lg md:text-2xl mr-4 text-black font-bold'>Logout</span>
          <img src={LogoutIcon} alt='icon-logout' />
        </div>
      </div>
    </nav>
  )
}

export default SideBar