import React,{ useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const Chart = () => {
  const [state,setState] = useState({
    series: [
      {
        name: 'Users',
        data: [31, 100, 28, 51, 42, 17, 100]
      },
      {
        name: 'Reviews',
        data: [11, 32, 45, 102, 34, 52, 41]
      },
      {
        name: 'Messages',
        data: [19, 15, 93, 28, 45, 59, 100]
      },
      {
        name: 'Subscribers',
        data: [1, 7, 33, 58, 15, 39, 13]
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ['2023-01-19T00:00:00.000Z', '2023-02-19T01:30:00.000Z', '2023-03-19T02:30:00.000Z', '2023-04-19T03:30:00.000Z', '2023-05-19T04:30:00.000Z', '2023-06-19T05:30:00.000Z', '2023-07-19T06:30:00.000Z']
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  })

  return (
    <div data-testid='chart' id="chart" className='w-full sm:w-[95%] mt-24'>
      <ReactApexChart options={state.options} series={state.series} type="area" height={450} />
    </div>
  )
}

export default Chart