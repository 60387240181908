import React,{ useState,useEffect } from 'react'

import PopUpModal from '../Components/PopUpModal'
import Loading from '../Components/Loading'
import { apiRequest } from '../Redux/ApiCalls'

const DashboardMessages = () => {
  const [messages,setMessages] = useState([])
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    setSelectedMessage(null)
  }


  const markAsRead = async(message) => {
    setSelectedMessage(message)
    const updatedMessages = [...messages]

    const messageIndex= updatedMessages.findIndex((exisitingMessage) => exisitingMessage.contactResponses.contactUid === message.contactUid)

    if (messageIndex !== -1) {
      try {
        await apiRequest.put(`contacts/${message.contactUid}`)
        updatedMessages[messageIndex].contactResponses.status = 'read'
        setMessages(updatedMessages)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const getMessages = async() => {
      setIsLoading(true)
      try {
        const res = await apiRequest.get('/contacts/0/50', { timeout: 30000 })
        setMessages(res.data.content)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
    getMessages()
  },[])


  return (
    <div className='w-full mx-auto p-4 md:p-8'>
      <h2 className='text-4xl text-headers font-bold my-8 mx-4 text-center md:text-left'>MESSAGES</h2>
      <div className='overflow-x-auto'>
        {isLoading && <div className="w-full"><Loading /></div>}
        {messages?.length >0 ?
          <div className=''>
            {messages.map(message =>
              <div data-testid='message'
                key={message.contactResponses.contactUid}
                className={`${message.contactResponses.status === 'read'? 'bg-white text-headers' : 'bg-headers text-white'} p-4 shadow-lg m-4 rounded-lg flex flex-col md:flex-row items-center`}
                onClick={() => markAsRead(message.contactResponses)}>
                <h3 className='basis-2/12 p-2 font-bold'>{message.contactResponses.email}</h3>
                <h2 className='basis-2/12 p-2 text-lg'>{message.contactResponses.name}</h2>
                <h2 className='basis-2/12 p-2 text-lg font-bold'>{message.contactResponses.subject}</h2>
                <p className='text-sm break-words max-w-[300px] md:max-w-[400px] lg:max-w-[600px] md:text-md basis-6/12 text-center md:text-left'>{message.contactResponses.content}</p>
                <span className='basis-1/12 text-sm font-bold p-2'>{message.contactResponses.createOn.slice(0,10)}</span>
              </div>)}
          </div>
          : <p>No Messages yet</p>}
        {selectedMessage !== null &&
          <PopUpModal
            date={selectedMessage.createOn.slice(0,10)}
            title={selectedMessage.subject}
            message={selectedMessage.content}
            name={selectedMessage.name}
            onCancel={closeModal}
          />}
      </div>
    </div>
  )
}

export default DashboardMessages