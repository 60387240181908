import React,{ useState } from 'react'

import Notification from '../Components/Notification'
import { apiRequest } from '../Redux/ApiCalls'
import { passwordValidation } from '../Helpers/helpers'
import eye from '../Assets/Icons/eye.svg'
import eyeCrossed from '../Assets/Icons/eye-crossed.svg'

const ResetPassword = () => {
  const [isLoading,setIsLoading] = useState(false)
  const [newPassword,setNewPassword] = useState('')
  const [confirmPassword,setConfirmPassword] = useState('')
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [showPassword, setShowPassword] = useState('password')
  const [showConfirmPassword, setShowConfirmPassword] = useState('password')

  const togglePassword = () => {
    showPassword==='password'?
      setShowPassword('text') :
      setShowPassword('password')
  }

  const toggleConfirmPassword = () => {
    showConfirmPassword==='password'?
      setShowConfirmPassword('text') :
      setShowConfirmPassword('password')
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    const isValidPassword = passwordValidation(newPassword)
    setIsLoading(true)
    if(!newPassword || !confirmPassword) {
      setIsLoading(false)
      setFailureMessage('All fields are required')
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else if (!isValidPassword) {
      setFailureMessage('Password should be at least 8 characters long and contain at least one special character, one number, and one uppercase letter.')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 10000)
    } else if (newPassword !== confirmPassword) {
      setIsLoading(false)
      setFailureMessage('Confirm password must be the same as password')
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else {
      try {
        const res = await apiRequest.put('/users/change/password',{ newPassword }, { timeout: 30000 })
        setIsLoading(false)
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 5000)
        setNewPassword('')
        setConfirmPassword('')
      } catch (error) {
        if (error.response) {
          setFailureMessage(error.response?.data.message)
        } else {
          setFailureMessage(error.message)
        }
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    }
  }

  return (
    <div className='px-4 md:px-20 py-32'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <h2 className='mb-8 text-4xl font-bold text-center'>Reset Password</h2>
      <div className="flex items-center justify-center">
        <form className='w-full md:w-2/3 lg:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="password" className='mb-1 text-lg font-bold'>Password*</label>
            <div className="flex items-center border rounded-lg">
              <input type={showPassword} name='password' value={newPassword} placeholder='New Password' maxLength='50'
                className='p-2 w-full rounded-lg' onChange={(e) => setNewPassword(e.target.value)}/>
              <div data-testid='toggle-password-button' onClick={togglePassword} className='p-2 cursor-pointer'>
                {showPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
              </div>
            </div>
          </div>
          <div className='flex flex-col w-full my-2'>
            <label htmlFor="confirmPassword" className='mb-1 text-lg font-bold'>Confirm Password*</label>
            <div className="flex items-center border rounded-lg">
              <input type={showConfirmPassword} name='confirmPassword' value={confirmPassword} placeholder='Confirm Password' maxLength='50'
                className='p-2 w-full rounded-lg' onChange={(e) => setConfirmPassword(e.target.value)} />
              <div data-testid='toggle-confirm-password-button' onClick={toggleConfirmPassword} className='p-2 cursor-pointer'>
                {showConfirmPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
              </div>
            </div>
          </div>
          <button data-testid='Reset Password' type='submit' className='flex items-center justify-start px-4 py-2 text-md text-white bg-headers rounded-lg
          font-semibold shadow-sm' disabled={isLoading}>
            {isLoading && <div className="animate-spin rounded-full h-[20px] w-[20px] border-t-4 border-b-4 border-white mr-4"></div>}
            {isLoading? 'Reseting...' : 'Reset Password'}</button>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword