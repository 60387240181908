import React from 'react'

const RegistrationConfirmation = () => {

  const handleSendConfirmation = () => {
    console.log('Confirmation email sent')
  }

  return (
    <div className='px-4 md:px-20 py-32 text-sm sm:text-xl md:text-2xl md:w-2/3 mx-auto'>
      <h2 className='mb-8 text-3xl md:text-4xl font-bold text-center'>Registration Confirmation</h2>
      <h1 className='my-8 text-4xl md:text-6xl font-bold text-center text-headers'>Thank you for registering!</h1>
      <p className='text-center'>
        To complete the registration process, please check your email
        and click on the confirmation link. If you did not receive the email, please click here to
        <span
          data-testid='confirmation email'
          className='cursor-pointer ml-2 text-undelined font-bold underline underline-offset-1 text-headers'
          onClick={handleSendConfirmation}>Resend confirmation email</span>.
      </p>
    </div>
  )
}

export default RegistrationConfirmation
