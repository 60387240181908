import FlightBookingImage from '../Assets/Images/travel.jpg'
import SECImage from '../Assets/Images/apis.jpg'
import flight_ticket from '../Assets/Images/flight-ticket.png'
import car from '../Assets/Images/car.png'
import cruise from '../Assets/Images/cruise.png'
import excurtion from '../Assets/Images/excurtion.png'
import expenses from '../Assets/Images/expenses.png'
import group_trip from '../Assets/Images/group-trip.png'
import hotel from '../Assets/Images/hotel.png'
import CICImage from '../Assets/Images/green-cloud.jpg'
import software from '../Assets/Images/software.png'
import stackassement from '../Assets/Images/assement.png'
import mutiple from '../Assets/Images/mutiple.png'
import vacation from '../Assets/Images/vacation.png'
import packaging from '../Assets/Images/packaging.png'
import app_modernisation from '../Assets/Images/modernisation.png'
import solution from '../Assets/Images/custom-solution.png'
import mobile from '../Assets/Images/mobile.png'
import web from '../Assets/Images/web.png'
import architecture from '../Assets/Images/architecture.png'
import consulting from '../Assets/Images/consultancy.png'
import code_review from '../Assets/Images/code_review.png'
import agile from '../Assets/Images/agile_transformation.png'
import maintenance from '../Assets/Images/software_maintenance.png'
import strategy from '../Assets/Images/cloud_strategy.png'
import cloud_architecture from '../Assets/Images/cloud_architecture.png'
import migration from '../Assets/Images/cloud_migration.png'
import security from '../Assets/Images/Cloud_security.png'
import cost from '../Assets/Images/cloud_cost.png'
import devops from '../Assets/Images/devops.png'


export const Sercives = [
  {
    id:1,
    title:'Mobility and Travels',
    image:FlightBookingImage,
    description:'Unlock your next adventure effortlessly! Seamless flight bookings, exclusive deals, and stress-free travel planning. Your journey begins with us!',
    products:[
      {
        id:1,
        name:'Flight Bookings',
        description:'Facilitate the booking of domestic and international flights for one-way, round-trip, or multi-city itineraries.',
        image: flight_ticket,
        subProducts:['Search and comparison of flight options', 'Flexible date and time search', 'Special deals and discounts']
      },
      {
        id:2,
        name:'Hotel Reservations',
        description:'Provide a platform for users to search, compare, and book accommodations ranging from budget hotels to luxury resorts.',
        image: hotel,
        subProducts:['Hotel reviews and ratings', 'Real-time availability updates', 'Exclusive hotel deals and promotions']
      },
      {
        id:3,
        name:'Car Rentals',
        description:'Offer the option to rent cars for local transportation or self-guided tours.',
        image: car,
        subProducts:['Search and comparison of rental car options', 'Rental car insurance options', 'Airport or city pickup/drop-off']
      },
      {
        id:4,
        name:'Vacation Packages',
        description:'Curated travel packages that include a combination of flights, hotels, and activities for a specific destination.',
        image: vacation,
        subProducts:['All-inclusive vacation options', 'Customizable packages', 'Group travel packages']
      },
      {
        id:5,
        name:'Cruise Bookings',
        description:'Facilitate reservations for cruise vacations, including different cruise lines, itineraries, and cabin categories.',
        image: cruise,
        subProducts:['Cruise line reviews and information', 'Cruise package deals', 'Special promotions and perks']
      },
      {
        id:6,
        name:'Activities and Excursions',
        description:'Offer a variety of tours, activities, and excursions at travel destinations.',
        image: excurtion,
        subProducts:['City tours, cultural experiences, adventure activities, etc.', 'User reviews and ratings', 'Booking of tickets and reservations']
      },
      {
        id:7,
        name:'Multi-Channel Booking',
        description:'Provide booking services through multiple channels, including websites, mobile apps, and partner platforms.',
        image: mutiple,
        subProducts:['Responsive website design', 'User-friendly mobile apps', 'API integrations with partners']
      },
      {
        id:8,
        name:'Group Travel Services',
        description:'Assist in organizing and booking group travel arrangements for events, conferences, or leisure trips.',
        image: group_trip,
        subProducts:['Group discounts and packages', 'Group itinerary coordination', 'Dedicated group travel specialists']
      },
      {
        id:9,
        name:'Expense Management for Businesses',
        description:'Offer solutions for businesses to manage and track travel expenses, bookings, and corporate travel policies.',
        image: expenses,
        subProducts:['Corporate travel portals', 'Centralized billing and invoicing', 'Reporting and analytics']
      },
      {
        id:10,
        name:'Dynamic Packaging',
        description:'Enable users to create customized travel packages by combining flights, hotels, and other services based on individual preferences.',
        image: packaging,
        subProducts:['Flexible itinerary creation', 'Real-time package pricing', 'Personalized recommendations']
      },
    ]
  },
  {
    id:2,
    title:'Cloud Infrastructure consulting',
    image:CICImage,
    description:'Our Cloud Consulting Excellence services are designed to empower your business with strategic cloud solutions, ensuring seamless transformation, security, and optimization.',
    products:[
      {
        id:1,
        name:'Cloud Strategy and Planning',
        description:'We help organizations define and develop a comprehensive cloud strategy aligned with business objectives. This includes assessing current infrastructure, identifying suitable cloud models (public, private, hybrid), and planning the migration or adoption strategy.',
        image: strategy,
        subProducts:['Cloud readiness assessment', 'Cloud adoption planning', 'Business case development']
      },
      {
        id:2,
        name:'Cloud Architecture and Design',
        description:'Experts focus on designing scalable, secure, and efficient cloud architectures. We work on creating blueprints for cloud solutions that align with organizational goals and industry best practices.',
        image: cloud_architecture,
        subProducts:['Cloud solution architecture', 'Infrastructure as Code (IaC) implementation', 'Security and compliance design']
      },
      {
        id:3,
        name:'Cloud Migration and Deployment',
        description:'We assist organizations in migrating existing systems to the cloud or deploying new applications in cloud environments. We ensure a smooth transition, minimizing downtime and optimizing performance.',
        image: migration,
        subProducts:['Migration planning and execution', 'Application refactoring', 'Data migration strategies']
      },
      {
        id:4,
        name:'Cloud Security',
        description:'Security-focused consultants specialize in ensuring the robustness of cloud environments. Our aim is to address concerns related to data protection, identity and access management, and compliance with industry regulations.',
        image: security,
        subProducts:['Security assessments', 'Identity management', 'Encryption and data protection']
      },
      {
        id:5,
        name:'Cost Management',
        description:'Our highly experienced team in this category help organizations optimize their cloud spending. We analyze usage patterns, recommend cost-effective solutions, and implement strategies to manage and reduce cloud-related expenses.',
        image: cost,
        subProducts:['Cost analysis and optimization', 'Budgeting and forecasting', 'Resource tagging and tracking']
      },
      {
        id:6,
        name:'DevOps and Automation on the Cloud',
        description:'Our experts focus on integrating development and operations processes within the cloud environment. We implement automation, continuous integration/continuous deployment (CI/CD), and other DevOps practices to streamline workflows.',
        image: devops,
        subProducts:['DevOps implementation', 'Automation scripting', 'CI/CD pipeline setup']
      },
    ]
  },
  {
    id:3,
    title:'Software Engineering consulting',
    image:SECImage,
    description:'Elevate your software development experience with us where innovation meets execution. As a leading Software Engineering Consultancy, we offer a comprehensive suite of services to drive the success of your software projects. From concept to deployment, we bring expertise, efficiency, and a passion for quality to every stage of the software development lifecycle.',
    products:[
      {
        id:1,
        name:'Software Development',
        description:'Full-stack software development services covering the entire development life cycle, from conceptualization to deployment.',
        image: software,
        subProducts:['Requirements analysis and specification', 'Custom software development', 'Frontend and backend development', 'Quality assurance and testing']
      },
      {
        id:2,
        name:'Application Modernization',
        description:'Consulting on upgrading and modernizing legacy applications to improve performance, scalability, and maintainability.',
        image: app_modernisation,
        subProducts:['Legacy system assessment', 'Technology stack migration', 'Code refactoring and optimization', 'Adoption of micro-service architecture']
      },
      {
        id:3,
        name:'Custom Software Solutions',
        description:'Tailored software solutions designed to meet specific business requirements and challenges.',
        image: solution,
        subProducts:['Custom application development', 'Bespoke software architecture', 'Solution prototyping']
      },
      {
        id:4,
        name:'Mobile App Development',
        description:'Design and development of mobile applications for iOS, Android, or cross-platform solutions.',
        image: mobile,
        subProducts:['Native and cross-platform development', 'UI/UX design for mobile apps', 'Mobile app testing and deployment']
      },
      {
        id:5,
        name:'Web Development',
        description:'Consulting on the development of web applications, websites, and web services.',
        image: web,
        subProducts:['Web application development', 'Content management system (CMS) implementation', 'E-commerce solutions']
      },
      {
        id:6,
        name:'Software Quality Assurance (QA) and Testing',
        description:'Services to ensure the quality and reliability of software through comprehensive testing strategies.',
        image: stackassement,
        subProducts:['Test planning and strategy', 'Automated and manual testing', 'Performance and security testing']
      },
      {
        id:7,
        name:'Software Architecture and Design',
        description:'Consulting on designing scalable, maintainable, and secure software architectures.',
        image: architecture,
        subProducts:['System architecture design', 'Microservices architecture', 'API design']
      },
      {
        id:8,
        name:'Technology Consulting',
        description:'Guidance on selecting the right technologies and frameworks based on business goals and requirements.',
        image: consulting,
        subProducts:['Technology assessment', 'Technology stack recommendation', 'Technology adoption strategy']
      },
      {
        id:9,
        name:'Code Review and Audits',
        description:'In-depth review of codebases to identify issues, ensure adherence to best practices, and optimize performance.',
        image: code_review,
        subProducts:['Code quality assessment', 'Security audits', 'Performance reviews']
      },
      {
        id:10,
        name:'Agile Transformation',
        description:'Assistance in adopting Agile methodologies for software development to enhance collaboration and adaptability.',
        image: agile,
        subProducts:['Agile coaching and training', 'Scrum and Kanban implementation', 'Agile process optimization']
      },
      {
        id:11,
        name:'Software Maintenance and Support',
        description:'Ongoing support and maintenance services to address issues, implement updates, and ensure software reliability.',
        image: maintenance,
        subProducts:['Bug fixing and troubleshooting', 'Version updates and patches', 'Performance monitoring and optimization']
      },
    ]
  },
]