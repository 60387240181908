import { ReactComponent as UsersIcon } from '../Assets/Icons/users.svg'
import { ReactComponent as ReviewsIcon } from '../Assets/Icons/comment.svg'
import { ReactComponent as MessagesIcon } from '../Assets/Icons/envelope.svg'
import { ReactComponent as SubscribersIcon } from '../Assets/Icons/subscribers.svg'

const cardItems = [
  {
    id: 1,
    title: 'Users',
    number: 60,
    lastNumber: 120,
    description: 'Current month',
    Icon: UsersIcon,
    color: '#004B77',
    background: '#cee2ee',
  },
  {
    id: 2,
    title: 'Reviews',
    number: 20,
    lastNumber: 10,
    description: 'Current month',
    Icon: ReviewsIcon,
    color: '#044404',
    background: '#c4e6c4',
  },
  {
    id: 3,
    title: 'Messages',
    number: 5,
    lastNumber: 2,
    description: 'Current month',
    Icon: MessagesIcon,
    color: '#cc8605',
    background: '#e7dac2',
  },
  {
    id: 4,
    title: 'Subscribers',
    number: 45,
    lastNumber: 21,
    description: 'Current month',
    Icon: SubscribersIcon,
    color: '#930050',
    background: '#FFC0CB',
  }
]

export default cardItems