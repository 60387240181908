import React from 'react'
import { Link } from 'react-router-dom'
import { Sercives } from '../Constants/Services'

const Services = () => {
  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <div className='flex flex-col items-center justify-center'>
        <h2 className='mb-4 font-bold text-3xl md:text-5xl text-center'>Get to Know Our Services</h2>
        <ul className='flex items-center justify-center flex-wrap text-text'>
          {Sercives.map(service =>
            <Link to={`/services/${service.title}`} key={service.id}>
              <li className='m-4 text-2xl cursor-pointer hover:bg-gray-100 p-2 rounded-lg'>{service.title}</li>
            </Link>
          )}
          {/* <li className='m-4 text-2xl cursor-pointer hover:bg-gray-100 p-2 rounded-lg'><Link to="/">Mobility and Travels</Link></li>
          <li className='m-4 text-2xl cursor-pointer hover:bg-gray-100 p-2 rounded-lg'><Link to="/services/Cloud Infrastructure consulting">Cloud consulting</Link></li>
          <li className='m-4 text-2xl cursor-pointer hover:bg-gray-100 p-2 rounded-lg'><Link to="/services/Software Engineering consulting">Software Consulting</Link></li> */}
          {/* <li className='m-4 text-2xl cursor-pointer hover:bg-gray-100 p-2 rounded-lg'><Link to="/services/E-commerce">E-Commerce</Link></li> */}
        </ul>
      </div>
      <div className='relative w-full sm:w-2/3 lg:w-1/2 mx-auto mt-8'>
        <div className='bg-extention w-[130px] h-[180px] absolute top-0 left-0 rounded-tl-[40%]'></div>
        <div className='relative z-10 pt-8 md:pt-16 pl-4 md:pl-16'>
          <h3 className='mb-4 font-bold text-4xl'>Choose a platform or service</h3>
          <p className='xl:w-2/3 text-xl text-text'>Our versatile experience allows us a better horizon to view,
            understand and perceive diverse innovative solutions.</p>
        </div>
      </div>
      <div className='relative'>
        <div className='border-4 border-strong w-[80px] h-[160px] absolute -top-8 lg:-top-16 right-48 rounded-[50%]'></div>
        <div className='bg-extention w-full absolute -right-4 md:-right-20 top-20 bottom-20 rounded-l-3xl'></div>
        <div className='relative z-10 flex flex-wrap items-center justify-center my-8'>
          {Sercives.map(service =>
            <Link to={`/services/${service.title}`}
              key={service.id} className='w-[300px] h-[300px] shadow-xl m-4 lg:m-8 bg-white rounded-lg ease-in-out duration-300 hover:translate-y-2'>
              <img src={service.image} alt={service.title} className='w-full h-[220px] object-cover rounded-t-lg' />
              <p className='my-5 text-2xl font-bold text-center'>{service.title}</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default Services