import axios from 'axios'
import AuthService from '../Components/AuthService'
import { baseURL } from '../Constants/baseURL'
import { passwordValidation } from '../Helpers/helpers'
import { getReviewsStart,getReviewsSuccess,getReviewsFailure,updateReviewStart,updateReviewSuccess,
  updateReviewFailure } from './reviewsRedux'
import { userLoginStart,userLoginSuccess,userLoginFailure,userLogoutSuccess,updateUserProfileStart,
  updateUserProfileSuccess,updateUserProfileFailure } from './currentUserRedux'
import { getUsersStart,getUsersSuccess,getUsersFailure,searchUsersByNameStart,searchUsersByNameSuccess,
  searchUsersByNameFailure,updateUserStart,updateUserSuccess,updateUserFailure,deleteUserStart,
  deleteUserSuccess,deleteUserFailure } from './usersRedux'

export const apiRequest = axios.create({ baseURL:baseURL })
apiRequest.interceptors.request.use((config) => {
  const token = AuthService.getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// LOGIN USER
export const userLogin = async (dispatch,user) => {
  const isValidPassword = passwordValidation(user.password)
  dispatch(userLoginStart())
  if (user.email === '' || user.password === '') {
    dispatch(userLoginFailure('All fields are required'))
    setTimeout(() => {
      dispatch(userLoginFailure(null))
    }, 5000)
  } else if (!isValidPassword) {
    dispatch(userLoginFailure('Password should be at least 8 characters long and contain at least one special character, one number, and one uppercase letter.'))
    setTimeout(() => {
      dispatch(userLoginFailure(null))
    }, 10000)
  } else{
    try {
      const res = await apiRequest.post('/users/login',user, { timeout: 30000 })
      dispatch(userLoginSuccess(res.data.data))
      AuthService.setToken(res.data.token)
    } catch (error) {
      if (error.response) {
        dispatch(userLoginFailure(error.response?.data.message))
      } else {
        dispatch(userLoginFailure(error.message))
      }
      setTimeout(() => {
        dispatch(userLoginFailure(null))
      }, 5000)
    }
  }
}

// USER LOG OUT
export const userLogout = async (dispatch) => {
  dispatch(userLogoutSuccess())
  AuthService.removeToken()
}

// UPDATE A USER PROFILE
export const updateUserProfile = async (id,dispatch,fieldToUpdate,updatedUser) => {
  dispatch(updateUserProfileStart())
  try {
    await apiRequest.put('/users',fieldToUpdate,{ timeout: 30000 })
    dispatch(updateUserProfileSuccess({ updatedUser }))
  } catch (error) {
    if (error.response) {
      dispatch(updateUserProfileFailure(error.response.data.message))
    } else {
      dispatch(getUsersFailure(error.message))
    }
    setTimeout(() => {
      dispatch(updateUserProfileFailure(null))
    }, 5000)
  }
}

// GET ALL USERS
export const getUsers = async (dispatch) => {
  dispatch(getUsersStart())
  try {
    const res = await apiRequest.get('/users/0/50', { timeout: 30000 })
    dispatch(getUsersSuccess(res.data.content))
  } catch (error) {
    if (error.response) {
      dispatch(getUsersFailure(error.response?.data.message))
    } else {
      dispatch(getUsersFailure(error.message))
    }
    setTimeout(() => {
      dispatch(getUsersFailure(null))
    }, 5000)
  }
}

// SEARCH ALL USERS BY NAME
export const searchUsersByName = async (dispatch,name) => {
  dispatch(searchUsersByNameStart())
  try {
    const res = await apiRequest.get(`/users/search/${name}`,{ timeout: 30000 })
    dispatch(searchUsersByNameSuccess(res.data))
  } catch (error) {
    if (error.message) {
      dispatch(searchUsersByNameFailure(error.message))
    } else {
      dispatch(searchUsersByNameFailure(error.response?.data.message))
    }
    setTimeout(() => {
      dispatch(searchUsersByNameFailure(null))
    }, 5000)
  }
}

// DELETE A USER
export const deleteUser = async (id,dispatch) => {
  dispatch(deleteUserStart())
  try {
    await apiRequest.delete(`/users/${id}`,{ timeout: 30000 })
    dispatch(deleteUserSuccess({ id }))
  } catch (error) {
    if (error.response) {
      dispatch(deleteUserFailure(error.response?.data.message))
    } else {
      dispatch(deleteUserFailure(error.message))
    }
    setTimeout(() => {
      dispatch(deleteUserFailure(null))
    }, 5000)
  }
}

// UPDATE A USER STATUS
export const updateUserStatus = async (id,updatedUser,dispatch,status) => {
  dispatch(updateUserStart())
  try {
    const res = await apiRequest.put(`/users/status/${ id }`,{ status:status },{ timeout: 30000 })
    dispatch(updateUserSuccess({ id,updatedUser }))
  } catch (error) {
    if (error.response) {
      dispatch(updateUserFailure(error.response?.data.message))
    } else {
      dispatch(updateUserFailure(error.message))
    }
    setTimeout(() => {
      dispatch(updateUserFailure(null))
    }, 5000)
  }
}

// UPDATE A USER ROLE
export const updateUserRole = async (id,updatedUser,dispatch,role) => {
  dispatch(updateUserStart())
  try {
    await apiRequest.put(`/users/role/${ id }`,{ role:role },{ timeout: 30000 })
    dispatch(updateUserSuccess({ id,updatedUser }))
  } catch (error) {
    if (error.response) {
      dispatch(updateUserFailure(error.response?.data.message))
    } else {
      dispatch(updateUserFailure(error.message))
    }
    setTimeout(() => {
      dispatch(updateUserFailure(null))
    }, 5000)
  }
}

// -------------------------------------------------------------------------------
// GET ALL REVIEWS
export const getReviews = async (dispatch) => {
  dispatch(getReviewsStart())
  try {
    const res = await apiRequest.get('/reviews/0/50',{ timeout: 30000 })
    dispatch(getReviewsSuccess(res.data))
  } catch (error) {
    if (error.message) {
      dispatch(getReviewsFailure(error.message))
    } else {
      dispatch(getReviewsFailure(error.response?.data.message))
    }
    setTimeout(() => {
      dispatch(getReviewsFailure(null))
    }, 5000)
  }
}

// UPDATE A REVIEW
export const updateReview = async (id,updatedReview,dispatch,visibility) => {
  dispatch(updateReviewStart())
  try {
    await apiRequest.put(`/reviews/visibility/${ id }`,{ visible:visibility },{ timeout: 30000 })
    dispatch(updateReviewSuccess({ id,updatedReview }))
  } catch (error) {
    if (error.response) {
      dispatch(updateReviewFailure(error.response?.data.message))
    } else {
      dispatch(updateReviewFailure(error.message))
    }
    setTimeout(() => {
      dispatch(updateReviewFailure(null))
    }, 5000)
  }
}
