import React from 'react'

const Carrier = () => {
  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <div className='mb-4'>
        <h2 className='mb-4 font-bold text-3xl text-center'>Choose the job offers that fit your competencies.</h2>
        <div className='h-[40vh] flex items-center justify-center'>
          <p className='text-text'>No Job Offer available yet.</p>
        </div>
      </div>
    </div>
  )
}

export default Carrier