import React,{ useState,useEffect } from 'react'

import Loading from '../Components/Loading'
import searchIcon from '../Assets/Icons/search.svg'
import { apiRequest } from '../Redux/ApiCalls'

const DashboardSubscribers = () => {
  const [subscribers,setSubscribers] = useState()
  const [search,setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getSubscribers = async() => {
    setIsLoading(true)
    try {
      const res = await apiRequest.get('/subscribers/0/50')
      setSubscribers(res.data.content)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSearch = async(e) => {
    e.preventDefault()
    setIsLoading(true)
    if(search !== '') {
      try {
        const res = await apiRequest.get(`/subscribers/search/${search}`, { timeout: 30000 })
        setSubscribers(res.data)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    } else {
      getSubscribers()
    }
  }

  useEffect(() => {
    getSubscribers()
  },[])

  return (
    <div className='w-full mx-auto p-4 md:p-8'>
      <div className='flex flex-col items-center justify-center md:flex-row md:justify-between my-8 mx-2'>
        <h2 className='text-4xl text-headers font-bold mb-4'>SUBSCRIBERS</h2>
        <form className='flex items-center bg-white pl-2 py-1 rounded-[100px] border' onSubmit={handleSearch}>
          <input type='text' placeholder='Search by email...' value={search} className='bg-white px-2 py-1 text-black w-full sm:w-72 focus:outline-none'
            onChange={(e) => setSearch(e.target.value)} />
          <button data-testid='searchSubscriber' type='submit' className='inline-block px-4 py-2 bg-headers hover:bg-strong
            text-white text-lg font-[700] rounded-[100px] ease-in-out duration-300'>
            <img src={searchIcon} alt='search-icon' />
          </button>
        </form>
      </div>
      <div className='overflow-x-auto'>
        {isLoading && <div className="w-full my-8"><Loading /></div>}
        {subscribers?.length >0 ?
          <table className='w-full overflow-hidden'>
            <thead>
              <tr className='border border-gray-300 bg-headers'>
                <th className='text-center text-sm md:text-lg text-white font-bold px-2 py-2'>ID</th>
                <th className='text-center text-sm md:text-lg text-white font-bold px-2 py-2'>Email</th>
                <th className='text-center text-sm md:text-lg text-white font-bold px-2 py-2'>Subscription Date</th>
              </tr>
            </thead>
            <tbody className='overflow-x-auto'>{subscribers && subscribers.map((subscriber,index) => (
              <tr key={subscriber.subscriberResponses.subscriberUId} className='border-b border-gray-300 p-2'>
                <td className='text-center text-sm md:text-lg text-text font-bold px-2 py-2'><span>{index+1}</span></td>
                <td className='text-center text-sm md:text-lg text-text font-bold px-2 py-2'><span>{subscriber.subscriberResponses.email}</span></td>
                <td className='text-center text-sm md:text-lg text-text font-bold px-2 py-2 whitespace-nowrap'>
                  <span>{subscriber.subscriberResponses.subscribedOn.slice(0,10)}</span>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
          : <p>No Subscribers yet</p>}
      </div>
    </div>
  )
}

export default DashboardSubscribers