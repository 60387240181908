import React from 'react'
import HeroImage from '../Assets/Images/about.png'

import { AboutUs,stacks } from '../Constants/About'

const About = () => {
  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <div className='flex flex-col-reverse lg:flex-row items-center'>
        <div className='flex-1 relative'>
          <div className='bg-extention w-[130px] h-[180px] absolute top-0 left-0 rounded-br-[60%]'></div>
          <div className='relative z-10 p-4'>
            <h1 className='text-headers text-4xl md:text-6xl xl:text-7xl lg:w-[80%] mb-4'>Get to know about us</h1>
            <p className='text-text md:w-[90%] pl-8 text-lg'>We are a company invested in creating value for our clients
            and customers by tapping into the potential inherent in our leadership and staff members.
             We are equally committed to the  continuous and wholesome growth and development of our workforce.</p>
          </div>
          <div className='bg-[#FFF5DB] w-[60px] h-[100px] absolute bottom-0 right-16 rounded-[50%]'></div>
        </div>
        <div className='flex-1 flex items-center justify-center mb-8 lg:mb-0'>
          <img src={HeroImage} alt='Hero-img' className='w-[90%]' />
        </div>
      </div>
      <div className='my-8 flex flex-col items-center justify-center relative'>
        <div className='w-full relative flex flex-col items-center my-8'>
          <h3 className='mb-2 font-bold text-3xl text-center'>Our Goal</h3>
          <p className='text-center text-text text-2xl'>Chart the path for creating innovative, seamless and simple solutions</p>
          <div className='absolute -bottom-8 -left-4 md:-left-20 w-4/5'>
            <div className='bg-headers h-[10px]'></div>
            <div className='bg-extention h-[10px]'></div>
          </div>
        </div>
        {AboutUs.map(item =>
          <div key={item.id} className='w-full flex flex-col items-center my-8'>
            <h3 className='mb-2 font-bold text-3xl text-center'>{item.title}</h3>
            <div className='flex flex-wrap justify-center items-center'>
              {item.title === 'Our Values' ?
                item.description.map(value =>
                  <div key={value.id} className='max-w-[350px] h-[370px] flex flex-col items-center justify-start bg-extention shadow-lg p-2 m-4 lg:m-8 rounded-lg'>
                    <div className='h-1/2 w-full flex items-center justify-center mb-4'>
                      <img src={value.img} alt={value.text} className='w-32'/>
                    </div>
                    <h3 className='text-center text-headers text-2xl font-bold'>{value.text}</h3>
                    <p className='text-center text-text text-lg'>{value.description}</p>
                  </div>)
                :<p className='text-center text-text text-2xl md:w-2/3 lg:w-1/2'>{item.description}</p>
              }
            </div>
          </div>
        )}
      </div>
      <div className='relative flex flex-col items-center justify-center mt-12 mb-8'>
        <div className='absolute -top-12 -right-4 md:-right-20 w-4/5'>
          <div className='bg-headers h-[10px]'></div>
          <div className='bg-extention h-[10px]'></div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center my-8'>
        <h3 className='mb-4 font-bold text-3xl text-center md:text-left'>Our tools for the ecosystem</h3>
        <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4'>
          {stacks.map(stack =>
            <div key={stack.id} className='w-24 m-2 flex items-center justify-center'>
              <img src={stack.logo} alt={stack.name} className='w-full' />
            </div>)}
        </div>
      </div>
    </div>
  )
}

export default About