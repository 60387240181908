import ReactLogo from '../Assets/Images/React.png'
import JavascritLogo from '../Assets/Images/JavaScript.png'
import KubernatesLogo from '../Assets/Images/Kubernetes.png'
import TypescriptLogo from '../Assets/Images/Typescript.png'
import ReactNativeLogo from '../Assets/Images/react-native.png'
import KotlinLogo from '../Assets/Images/kotlin.png'
import FlutterLogo from '../Assets/Images/flutter.png'
import SwiftLogo from '../Assets/Images/swift.jpg'
import GoLogo from '../Assets/Images/go.png'
import JavaLogo from '../Assets/Images/Java.png'
import SpringBootLogo from '../Assets/Images/SpringBoot.png'
import SonarqubeLogo from '../Assets/Images/Sonarqube.png'
import DockerLogo from '../Assets/Images/Docker.png'
import VueLogo from '../Assets/Images/vue.png'
import AwsLogo from '../Assets/Images/aws.png'
import NatsLogo from '../Assets/Images/nats.png'
import KafkaLogo from '../Assets/Images/kafka.png'
import InnovationPicture from '../Assets/Images/innovation.png'
import QualityPicture from '../Assets/Images/quality.png'
import CreativityPicture from '../Assets/Images/creativity.png'
import SustainabilityPicture from '../Assets/Images/sustainability.png'
import TechnologicalPicture from '../Assets/Images/technology.png'

export const AboutUs = [
  {
    id:1,
    title:'Our Mission',
    description:'We are committed to spearheading the integration of Information Technology solutions across diverse sectors in emerging regions, propelling economic activities and fostering a robust economic landscape in the areas where we operate'
  },
  {
    id:2,
    title:'Our Values',
    description:[
      {
        id:1,
        img: QualityPicture,
        text:'Quality',
        description:'In the hallowed halls of craftsmanship, quality is the silent pact between creator and creation. It is the promise etched into each stroke of genius, each line of code, and every brushstroke of artistry. It is the unspoken covenant that transforms artifacts into heirlooms and innovations into legacies.'
      },
      {
        id:2,
        img:InnovationPicture,
        text:'Innovation',
        description:'As we stand on the precipice of what is known, innovation urges us to take the leap into the unknown, where the seeds of progress are sown. It is the bridge between what is and what can be, beckoning us to construct a reality that surpasses even our most audacious aspirations.'
      },
      {
        id:3,
        img:CreativityPicture,
        text:'Adaptive creativity',
        description:'We turn new and imaginative ideas into reality'
      },
      {
        id:4,
        img:SustainabilityPicture,
        text:'Sustainability',
        description:'We support ecological, human, and economic health and vitality.'
      },
      {
        id:5,
        img:TechnologicalPicture,
        text:'Technological advancement and prowess',
        description:'We apply practical sciences to industry.'
      },
    ]
  },
  {
    id:3,
    title:'Our Culture',
    description:'We believe in achieving great strides with tiny little steps forward and in the right direction. We are open to learning new ways of improvements and accepting constructive criticisms as we tirelessly aim to get it right on time the first time.'
  },
]



export const stacks = [
  {
    id:1,
    name:'React',
    logo:ReactLogo
  },
  {
    id:2,
    name:'Javascrit',
    logo:JavascritLogo
  },
  {
    id:3,
    name:'Kubernates',
    logo:KubernatesLogo
  },
  {
    id:4,
    name:'Typescript',
    logo:TypescriptLogo
  },
  {
    id:5,
    name:'ReactNative',
    logo:ReactNativeLogo
  },
  {
    id:6,
    name:'Kotlin',
    logo:KotlinLogo
  },
  {
    id:7,
    name:'Flutter',
    logo:FlutterLogo
  },
  {
    id:8,
    name:'Swift',
    logo:SwiftLogo
  },
  {
    id:9,
    name:'Go',
    logo:GoLogo
  },
  {
    id:10,
    name:'Java',
    logo:JavaLogo
  },
  {
    id:11,
    name:'SpringBoot',
    logo:SpringBootLogo
  },
  {
    id:12,
    name:'Sonarqube',
    logo:SonarqubeLogo
  },
  {
    id:13,
    name:'Docker',
    logo:DockerLogo
  },
  {
    id:14,
    name:'Vue',
    logo:VueLogo
  },
  {
    id:15,
    name:'AWS',
    logo:AwsLogo
  },
  {
    id:16,
    name:'NATS',
    logo:NatsLogo
  },
  {
    id:17,
    name:'KAFKA',
    logo:KafkaLogo
  },
]