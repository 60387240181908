import React, { useState,useEffect } from 'react'

import Loading from '../Components/Loading'
import { apiRequest } from '../Redux/ApiCalls'
import cardItems from '../Constants/CardItems'
import Chart from '../Components/Chart'
import Card from '../Components/Card'


const Dashboard = () => {
  const [counts,setCounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const combinedData = counts.map((counts,index) => ({
    number:counts,
    design:cardItems[index]
  }))

  useEffect(() => {
    const getCounts = async() => {
      setIsLoading(true)
      try {
        const res = await apiRequest.get('/dashboard/count', { timeout: 30000 })
        setCounts([res.data.users,res.data.reviews,res.data.messages,res.data.subscribers])
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }
    getCounts()
  },[])

  return (
    <div className='p-4 md:p-8 flex flex-col items-center justify-center'>
      <div className='flex items-center justify-around flex-wrap'>
        {isLoading && <div className="w-full"><Loading /></div>}
        {combinedData.map(item => <Card key={item.design.id} item={item.design} number={item.number} />)}
      </div>
      <Chart />
    </div>
  )
}

export default Dashboard