import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PopUpModal from '../Components/PopUpModal'
import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import { getUsers,deleteUser,updateUserRole,updateUserStatus,searchUsersByName } from '../Redux/ApiCalls'
import trash from '../Assets/Icons/trash.svg'
import searchIcon from '../Assets/Icons/search.svg'


const DashboardUsers = () => {
  const { currentUser } = useSelector(state => state.currentUser)
  const { users,error,success,isFetching } = useSelector(state => state.users)
  const dispatch = useDispatch()
  const [search,setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)

  const cancelAction = () => {
    setSelectedUser(null)
  }

  const handleDelete = (id) => {
    if (id !== currentUser.userUid) {
      setSelectedUser({ id, action: 'delete', text: 'Are you sure you want to delete this user?' })
    }
  }

  const handleVisibility = (id) => {
    if (id !== currentUser.userUid) {
      setSelectedUser({ id, action: 'visibility', text:'Are you sure you want to change the visibility of this user?' })
    }
  }

  const makeUserAdmin = (id) => {
    if (id !== currentUser.userUid) {
      setSelectedUser({ id, action: 'makeAdmin', text:'Are you sure you want to change this user Priviladges?' })
    }
  }

  const confirmAction = () => {
    const { id, action } = selectedUser

    if (action === 'delete') {
      deleteUser(id,dispatch)
    } else if (action === 'visibility') {
      const userToUpdate = users.find(user => user.data.userUid === id)
      const updatedUser = { ...userToUpdate.data,status:`${ userToUpdate.data.status === 'active'? 'inactive' : 'active' }` }
      updateUserStatus(id,updatedUser,dispatch,`${ userToUpdate.data.status === 'active'? 'inactive' : 'active' }`)
    } else if (action === 'makeAdmin') {
      const userToUpdate = users.find(user => user.data.userUid === id)
      const updatedUser = { ...userToUpdate.data,userRole:`${userToUpdate.data.userRole === 'NORMAL_USER'? 'ADMIN_USER' : 'NORMAL_USER' }` }
      updateUserRole(id,updatedUser,dispatch,`${ userToUpdate.data.userRole === 'NORMAL_USER'? 'ADMIN_USER' : 'NORMAL_USER' }`)
    }

    setSelectedUser(null)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    if(search !== '') {
      searchUsersByName(dispatch,search)
    } else {
      getUsers(dispatch)
    }
  }

  useEffect(() => {
    getUsers(dispatch)
  },[dispatch])

  return (
    <div className='w-full mx-auto p-4 md:p-8'>
      <Notification failure={error} success={success} color={success? 'green' : 'red'} />
      {selectedUser !== null &&
        <PopUpModal
          confirm="Confirmation"
          message={selectedUser?.text}
          onCancel={cancelAction}
          onConfirm={confirmAction}
        />
      }
      <div className='flex flex-col items-center justify-center md:flex-row md:justify-between my-12 mx-2'>
        <h2 className='text-4xl text-headers font-bold mb-4'>USERS</h2>
        <form className='flex items-center bg-white pl-2 py-1 rounded-[100px] border' onSubmit={handleSearch}>
          <input type='text' placeholder='Search by name...' value={search} className='bg-white px-2 py-1 text-black w-full sm:w-72 focus:outline-none'
            onChange={(e) => setSearch(e.target.value)} />
          <button data-testid='search' type='submit' className='inline-block px-4 py-2 bg-headers hover:bg-strong
            text-white text-lg font-[700] rounded-[100px] ease-in-out duration-500' disabled={isFetching}>
            <img src={searchIcon} alt='search-icon' />
          </button>
        </form>
      </div>
      { isFetching && <div className="w-full my-4"><Loading /></div> }
      <div className='overflow-x-auto'>
        { (users?.length > 0) ?
          <table className='w-full overflow-hidden'>
            <thead>
              <tr className='border border-gray-300 bg-headers'>
                <th className='text-left text-sm md:text-lg text-white font-bold px-2 py-2'>ID</th>
                <th className='text-left text-sm md:text-lg text-white font-bold px-2 py-2'>Avatar</th>
                <th className='text-left text-sm md:text-lg text-white font-bold px-2 py-2'>Names</th>
                <th className='text-left text-sm md:text-lg text-white font-bold px-2 py-2'>Email</th>
                <th className='text-left text-sm md:text-lg text-white font-bold px-2 py-2'>Join Date</th>
                <th className='text-left text-sm md:text-lg text-white font-bold px-12 py-2'>Operation</th>
              </tr>
            </thead>
            {users &&
            <tbody className='overflow-x-auto'>{users.map((user,index) => (
              <tr key={user?.data.userUid} className='border-b border-gray-300 p-2'>
                <td className='text-left text-sm md:text-lg text-text font-bold px-2 py-2'><span>{index+1}</span></td>
                <td className='text-left text-sm md:text-lg text-text font-bold px-2 py-2'>
                  {user.data?.userPicture ?
                    <img src={user.data.userPicture} alt="avatar" className='w-12 h-12 rounded-full' /> :
                    <div className='w-12 h-12 rounded-full bg-headers flex items-center justify-center'>
                      <p className='text-white font-bold text-2xl'>{user.data?.userFirstName.slice(0,2).toUpperCase()}</p>
                    </div>}
                </td>
                <td className='text-left text-sm md:text-lg text-text font-bold px-2 py-2 whitespace-nowrap'><span>{user.data?.userFirstName} {user.data?.userLastName}</span></td>
                <td className='text-left text-sm md:text-lg text-text font-bold px-2 py-2'><span>{user.data?.userEmail}</span></td>
                <td className='text-left text-sm md:text-lg text-text font-bold px-2 py-2 whitespace-nowrap'><span>{user.data?.registrationDate?.slice(0,10)}</span></td>
                <td className='flex items-center text-left text-sm md:text-lg text-text font-bold px-2 py-2'>
                  <button
                    data-testid='delete'
                    className='mx-2'
                    onClick={() => handleDelete(user.data?.userUid)}
                    disabled={isFetching}>
                    <img src={trash} alt='delete-icon' />
                  </button>
                  <button
                    data-testid='visible'
                    className='mx-2'
                    onClick={() => handleVisibility(user.data?.userUid)}
                    disabled={isFetching}>
                    <span style={{ backgroundColor:`${ user.data?.status === 'active' ? '#008000': '#990000'}` }} className='px-2 py-1 rounded-md text-white mx-2 text-sm'>
                      { user.data?.status === 'active' ? 'Activate': 'Deactivate'}
                    </span>
                  </button>
                  <button
                    data-testid='Admin'
                    className={`${user.data?.userRole !== 'NORMAL_USER'? 'bg-headers':'bg-text'} px-2 py-1 rounded-md text-white mx-2 text-sm`}
                    onClick={() => makeUserAdmin(user.data?.userUid)}
                    disabled={isFetching}>Admin</button>
                </td>
              </tr>
            ))}
            </tbody>}
          </table>
          : <p>No Registered user yet</p>}
      </div>
    </div>
  )
}

export default DashboardUsers