import React from 'react'

const Projects = () => {
  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <div className='flex flex-col items-center justify-center'>
        <h2 className='mb-4 font-bold text-3xl sm:text-5xl text-center'>Get to Know Our Work</h2>
        <ul className='flex items-center text-text flex-wrap justify-center'>
          <li className='m-4 text-lg sm:text-2xl font-bold p-2 sm:p-4 shadow-md rounded-sm text-white bg-strong'>See All</li>
          <li className='m-4 text-lg sm:text-2xl font-bold p-2 sm:p-4 shadow-md rounded-sm'>Web App</li>
          <li className='m-4 text-lg sm:text-2xl font-bold p-2 sm:p-4 shadow-md rounded-sm'>Mobile App</li>
          <li className='m-4 text-lg sm:text-2xl font-bold p-2 sm:p-4 shadow-md rounded-sm'>APIs</li>
        </ul>
      </div>
      <div className='h-[50vh] flex items-center justify-center'>
        <p className='text-text'>No published project yet</p>
      </div>
    </div>
  )
}

export default Projects