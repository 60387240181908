import React from 'react'

const CookiesPolicy = () => {
  return (
    <div className='px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl'>
      <h1 className='text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16'>Cookies Policy</h1>
      <div>
        <p className='mb-4'>Our Cookies Policy was last updated on 15/01/2024.</p>
        <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can
          understand what type of cookies We use, or the information We collect using Cookies and how that information is used.
          This Cookies Policy was generated by Terms Feed Cookies Policy Generator. Cookies do not typically contain any information
          that personally identifies a user, but personal information that we store about You may be linked to the information stored
          in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our
        <a href='/privacyPolicy'><strong> Privacy Policy</strong></a>. We do not store sensitive personal information, such as mailing addresses,
          account passwords, etc. in the Cookies We use.
        </p>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Interpretation and Definitions</h3>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
          </div>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Definitions</h3>
            <p>For the purposes of this Cookies Policy:</p>
            <div>
              <p><span className='font-bold mr-2'>Company</span>Thrypes.</p>
              <p><span className='font-bold mr-2'>Cookies</span>means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</p>
              <p><span className='font-bold mr-2'>Website</span>refers to <strong>Thrypes</strong>, accessible from <a href='https://thrypes.com'><strong>thrypes.com</strong></a></p>
              <p><span className='font-bold mr-2'>you</span>means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.</p>
            </div>
          </div>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>The use of the Cookies</h3>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Type of Cookies We Use</h3>
            <p>Cookies can be Persistent or Session Cookies. Persistent Cookies remain on your personal computer or
              mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
            </p>
          </div>
          <p>We use both session and persistent Cookies for the purposes set out below:</p>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Necessary / Essential Cookies</h3>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us </p>
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable
              You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts.
              Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to
              provide You with those services.
            </p>
          </div>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Cookies Policy / Notice Acceptance Cookies</h3>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us </p>
            <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
          </div>
          {/* <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Functionality Cookies</h3>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us </p>
            <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your
              login details or language preference. The purpose of these Cookies is to provide You with a more personal experience
              and to avoid You having to re-enter your preferences every time You use the Website.</p>
          </div>
          <div>
            <h3 className='text-md md:text-2xl font-bold mt-2'>Tracking and Performance Cookies</h3>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Third-Parties</p>
            <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website.
              The information gathered via these Cookies may directly or indirectly identify you as an individual visitor.
              This is because the information collected is typically linked to a pseudonymous identifier associated with the
              device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features
              or new functionality of the Website to see how our users react to them.</p>
          </div> */}
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Your Choices Regarding Cookies</h3>
          <div>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser
            and then delete the Cookies saved in your browser associated with this website. You may use this option for
            preventing the use of Cookies at any time. If You do not accept Our Cookies, You may experience some inconvenience
            in your use of the Website and some features may not function properly. If You would like to delete Cookies or instruct
            your web browser to delete or refuse Cookies, please visit the help pages of your web browser.
          <br />
          <p>For the Chrome web browser, please visit this page from Google:
            <a href='https://support.google.com/accounts/answer/32050'><strong> HERE</strong></a></p>
          <p>For the Internet Explorer web browser, please visit this page from Microsoft:
            <a href='https://support.microsoft.com/kb/278835'><strong> HERE</strong></a></p>
          <p>For the Firefox web browser, please visit this page from Mozilla:
            <a href='ttps://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored'><strong> HERE</strong></a></p>
          <p>For the Safari web browser, please visit this page from Apple:
            <a href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac'><strong> HERE</strong></a></p>
            For any other web browser, please visit your web browser official web pages.
          </div>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Contact Us</h3>
            If you have any questions about this Cookies Policy, You can contact us:
          <p>By visiting this page on our website: <a href='/contacts'><strong>Contacts</strong></a></p>
          <p>By sending us an email: <strong>data.privacy@thrypes.com</strong></p>
        </div>
      </div>
    </div>
  )
}

export default CookiesPolicy