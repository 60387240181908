import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className='px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl'>
      <h1 className='text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16'>Privacy Policy</h1>
      <div>
        <p className='mb-4'>Last updated: 15/01/2024 </p>
        <p>Our Company Thrypes, operates on <strong>https://thrypes.com</strong> Site.
          This page informs you of our policies regarding the collection, use and disclosure of Personal Information
          we receive from users of the Site.
          We use your Personal Information only for providing and improving the Site. By using the Site, you agree
          to the collection and use of information in accordance with this policy.
        </p>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Information Collection And Use</h3>
          <p>While using our Site, we may ask you to provide us with certain personally identifiable
            information that can be used to contact or identify you. Personally identifiable information may include,
            but is not limited to your Personal Information.
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Log Data</h3>
          <p>Like many site operators, we collect information that your browser sends whenever you visit our Site (Log Data).
            This Log Data may include information such as your computers Internet Protocol (IP) address, browser type,
            browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on
            those pages and other statistics.In addition, we may use third party services such as Google Analytics that collect,
            monitor and analyze this …
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Communications</h3>
          <p>We may use your Personal Information to contact you with newsletters, marketing or promotional
            materials and other information that ...
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Cookies</h3>
          <p>Cookies are files with small amount of data, which may include an anonymous unique identifier.
            Cookies are sent to your browser from a web site and stored on your computers hard drive.
            Like many sites, we use cookies to collect information. You can instruct your browser to refuse
            all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you
            may not be able to use some portions of our Site.
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Security</h3>
          <p>The security of your Personal Information is important to us, but remember that no method of transmission
            over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Changes To This Privacy Policy</h3>
          <p>This Privacy Policy is effective as of 15/01/2024 and will remain in effect except with respect to any changes
            in its provisions in the future, which will be in effect immediately after being posted on this page.
            We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy
            periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page
            will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified
            Privacy Policy.
            If we make any material changes to this Privacy Policy, we will notify you either through the email address you have
            provided us, or by placing a prominent notice on our website.
          </p>
        </div>
        <div>
          <h3 className='text-lg md:text-3xl font-bold mt-4'>Contact Us</h3>
          If you have any questions about this Privacy Policy, You can contact us:
          <p>By visiting this page on our website: <a href='/contacts'><strong>Contacts</strong></a></p>
          <p>By sending us an email: <strong>data.privacy@thrypes.com</strong></p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy