import React from 'react'

const TermsAndConditions = () => {
  return (
    <div className='px-4 md:px-20 py-8 text-sm sm:text-md md:text-xl'>
      <h1 className='text-center text-4xl md:text-5xl my-8 md:my-12 lg:my-16'>Terms and Conditions</h1>
      <div>
        <p>Our Terms and Conditions were last updated on 15/01/2024.</p>
        <p>Please read these terms and conditions carefully before using Our Service.</p>
        <div className='mt-4'>
          <h2 className='text-xl md:text-4xl font-bold'>Interpretation and Definitions</h2>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Definitions</h3>
            <p>For the purposes of these Terms and Conditions:</p>
            <div>
              <p><span className='font-bold mr-2'>Affiliate</span>means an entity that controls,is controlled by or is under common control with a party, where control means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
              <p><span className='font-bold mr-2'>Account</span>means a unique account created for You to access our Service or parts of our Service. </p>
              <p><span className='font-bold mr-2'>Company</span>THRYPES</p>
              <p><span className='font-bold mr-2'>Country</span>refers to Germany. </p>
              <p><span className='font-bold mr-2'>Content</span>refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content. </p>
              <p><span className='font-bold mr-2'>Device</span>means any device that can access the Service such as a computer, a cellphone or a digital tablet. </p>
              <p><span className='font-bold mr-2'>Feedback</span>means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service. </p>
              <p><span className='font-bold mr-2'>Products</span>refer to the products or items offered for sale on the Service.</p>
              <p><span className='font-bold mr-2'>Orders</span>mean a request by You to purchase Products from Us. </p>
              <p><span className='font-bold mr-2'>Promotions</span>refer to contests, sweepstakes or other promotions offered through the Service. </p>
              <p><span className='font-bold mr-2'>Service</span>refers to the Website. </p>
              <p><span className='font-bold mr-2'>Terms and Conditions</span>(also referred as Terms) mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions Agreement was generated by Terms Feed Terms and Conditions Generator. </p>
              <p><span className='font-bold mr-2'>Third-party Social Media Service</span>means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service. </p>
              <p><span className='font-bold mr-2'>Website</span>refers to  <strong>Thrypes</strong>, accessible from <a href='https://thrypes.com'><strong>thrypes.com</strong></a></p>
              <p><span className='font-bold mr-2'>You</span>means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </div>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Acknowledgment</h3>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement
               that operates between You and the Company. These Terms and Conditions set out the rights
               and obligations of all users regarding the use of the Service. Your access to and use of
               the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
               These Terms and Conditions apply to all visitors, users and others who access or use the Service.
               By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
               with any part of these Terms and Conditions then You may not access the Service. You represent that you
               are over the age of 18. The Company does not permit those under 18 to use the Service. Your access to and
               use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company.
               Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal
               information when You use the Application or the Website and tells You about Your privacy rights and how the
               law protects You. Please read Our Privacy Policy carefully before using Our Service.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>User Accounts</h3>
            <p>When You create an account with Us, You must provide Us information that is accurate,
              complete, and current at all times. Failure to do so constitutes a breach of the Terms,
              which may result in immediate termination of Your account on Our Service. You are responsible
              for safeguarding the password that You use to access the Service and for any activities or
              actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.
              You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware
              of any breach of security or unauthorized use of Your account. You may not use as a username the name of another
              person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of
              another person or entity other than You without appropriate authorization, or a name that is otherwise offensive,
              vulgar or obscene.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Copyright Policy</h3>
            <div>
              <h3 className='text-md md:text-2xl font-bold mt-2'>Intellectual Property Infringement</h3>
              <p>We respect the intellectual property rights of others. It is Our policy to respond to
                any claim that Content posted on the Service infringes a copyright or other intellectual
                property infringement of any person. If You are a copyright owner, or authorized on behalf of one,
                and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement
                that is taking place through the Service, You must submit Your notice in writing to the attention of our
                copyright agent via email <strong>data.privacy@thrypes.com</strong> and include in Your notice a detailed description
                of the alleged infringement. You may be held accountable for damages (including costs and attorneys fees)
                for misrepresenting that any Content is infringing Your copyright.
              </p>
            </div>
            <div>
              <h3 className='text-md md:text-2xl font-bold mt-2'>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</h3>
              <div>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright
                Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
              <ul className='list-disc pl-4'>
                <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.</li>
                <li>A description of the copyrighted work that You claim has been infringed, including the URL
                  (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
                </li>
                <li>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li>
                <li>Your address, telephone number, and email address.</li>
                <li>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright
                  owner, its agent, or the law.
                </li>
                <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and
                  that You are the copyright owner or authorized to act on the copyright owner behalf. You can contact our
                  copyright agent via email <strong>data.privacy@thrypes.com</strong>. Upon receipt of a notification, the Company will
                  take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content
                  from the Service.
                </li>
              </ul>
              </div>
            </div>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Intellectual Property</h3>
            <p>The Service and its original content (excluding Content provided by You or other users),
              features and functionality are and will remain the exclusive property of the Company and its licensors.
              The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
              Our trademarks and trade dress may not be used in connection with any product or service without the prior
              written consent of the Company.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Your Feedback to Us</h3>
            <p>You assign all rights, title and interest in any Feedback You provide the Company.
              If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive,
              perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license,
              distribute, modify and exploit such Feedback without restriction
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Links to Other Websites</h3>
            <p>Our Service may contain links to third-party web sites or services that are not owned or
              controlled by the Company. The Company has no control over, and assumes no responsibility for,
              the content, privacy policies, or practices of any third party web sites or services. You further
              acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly,
              for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance
              on any such content, goods or services available on or through any such web sites or services. We strongly
              advise You to read the terms and conditions and privacy policies of any third-party web sites or services that
              You visit.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Termination</h3>
            <p>We may terminate or suspend Your Account immediately, without prior notice or liability,
              for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
              Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account,
              You may simply discontinue using the Service.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Limitation of Liability</h3>
            <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
              suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall
              be limited to the amount actually paid by You through the Service or 100 USD if You have not purchased
              anything through the Service. To the maximum extent permitted by applicable law, in no event shall the
              Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever
              (including, but not limited to, damages for loss of profits, loss of data or other information,
              for business interruption, for personal injury, loss of privacy arising out of or in any way related to the
              use of or inability to use the Service, third-party software and/or third-party hardware used with the Service,
              or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised
              of the possibility of such damages and even if the remedy fails of its essential purpose. Some states do not allow
              the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means
              that some of the above limitations may not apply. In these states, each part liability will be limited to the
              greatest extent permitted by law.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>AS IS and AS AVAILABLE Disclaimer</h3>
            <p>The Service is provided to You AS IS and AS AVAILABLE and with all faults and defects without warranty of any kind.
              To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates
              and its and their respective licensors and service providers, expressly disclaims all warranties, whether express,
              implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability,
              fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing,
              course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty
              or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any
              intended results, be compatible or work with any other software, applications, systems or services, operate without
              interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will
              be corrected. Without limiting the foregoing, neither the Company nor any of the company provider makes any
              representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service,
              or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
              error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the
              Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are
              free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. Some jurisdictions
              do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer,
              so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and
              limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Governing Law</h3>
            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service.
              Your use of the Application may also be subject to other local, state, national, or international laws.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Disputes Resolution</h3>
            <p>If You have any concern or dispute about the Service, You agree to first
              try to resolve the dispute informally by contacting the Company.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Severability and Waiver</h3>
            <div>
              <h3 className='text-md md:text-2xl font-bold mt-2'>Severability</h3>
              <p>If any provision of these Terms is held to be unenforceable or invalid,
                such provision will be changed and interpreted to accomplish the objectives of such
                provision to the greatest extent possible under applicable law and the remaining provisions
                will continue in full force and effect.
              </p>
            </div>
            <div>
              <h3 className='text-md md:text-2xl font-bold mt-2'>Waiver</h3>
              <p>Except as provided herein, the failure to exercise a right or to require performance of an
                obligation under this Terms shall not effect a part ability to exercise such right or require
                such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of
                any subsequent breach.
              </p>
            </div>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Changes to These Terms and Conditions</h3>
            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time.
              If a revision is material We will make reasonable efforts to provide at least 30 days notice prior
              to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
              By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
              revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.
            </p>
          </div>
          <div>
            <h3 className='text-lg md:text-3xl font-bold mt-4'>Contact Us</h3>
            If you have any questions about these Terms and Conditions, You can contact us:
            <p>By visiting this page on our website: <a href='/contacts'><strong>Contacts</strong></a></p>
            <p>By sending us an email: <strong>data.privacy@thrypes.com</strong></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions