import React,{ useState,useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'

import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import { getReviews,updateReview } from '../Redux/ApiCalls'
import eye from '../Assets/Icons/eye.svg'
import eyeCrossed from '../Assets/Icons/eye-crossed.svg'
import checkedIcon from '../Assets/Icons/star.svg'
import uncheckedIcon from '../Assets/Icons/uncheckStar.svg'

const DashboardReviews = () => {
  const dispatch = useDispatch()
  const { reviews } = useSelector(state => state.reviews?.reviews)
  const { success,error,isFetching } = useSelector(state => state.reviews)
  const [filteredReviews,setFilteredReviews] = useState(reviews)

  const handleSearch = (stars) => {
    if(stars > 0 && stars < 6) {
      const filteredReviews = reviews.filter(review => review.data.rating === Number(stars))
      setFilteredReviews(filteredReviews)
    } else {
      setFilteredReviews(reviews)
    }
  }

  const handleVisibility = (id) => {
    const reviewToUpdate = reviews.find(review => review.data.reviewUid === id)
    const updatedReview = { ...reviewToUpdate.data,visibility:`${reviewToUpdate.data.visibility === 'true'? 'false' : 'true'}` }
    updateReview(id,updatedReview,dispatch,`${reviewToUpdate.data.visibility === 'true'? 'false' : 'true'}`)
  }

  useEffect(() => {
    setFilteredReviews(reviews)
  },[reviews])

  useEffect(() => {
    getReviews(dispatch)
  },[dispatch])

  return (
    <div className='w-full mx-auto p-4 md:p-8'>
      <Notification failure={error} success={success} color={success? 'green' : 'red'} />
      <div className='flex flex-col items-center justify-center md:flex-row md:justify-between my-8 mx-2'>
        <h2 className='text-4xl text-headers font-bold mb-4'>REVIEWS</h2>
        <div className='flex m-4'>
          <select data-testid='select' className='p-2 my-2 font-[600] rounded bg-headers text-white font-bold' onChange={(e) => handleSearch(e.target.value)}>
            <option className='font-bold' value=''>--Filter Stars--</option>
            <option className='font-bold' value='1'>1</option>
            <option className='font-bold' value='2'>2</option>
            <option className='font-bold' value='3'>3</option>
            <option className='font-bold' value='4'>4</option>
            <option className='font-bold' value='5'>5</option>
          </select>
        </div>
      </div>
      {isFetching && <div className='w-full my-4'><Loading /></div>}
      <div className='overflow-x-auto'>
        {filteredReviews?.length >0 ?
          <div className=''>
            {filteredReviews.map(review =>
              <div key={review.data.reviewUid} className='p-4 shadow-lg m-4 rounded-lg flex flex-col md:flex-row items-center'>
                <div className='basis-1/12 flex-start'>
                  {review.data.userPicture?
                    <img src={review.data.userPicture} alt="avatar" className='w-[50px] h-[50px] rounded-full' /> :
                    <div className='w-[50px] h-[50px] rounded-full bg-headers flex items-center justify-center'>
                      <p className='text-white font-bold text-2xl'>{review.data.userFirstName?.slice(0,2).toUpperCase()}</p>
                    </div>}
                </div>
                <h3 className='basis-2/12 p-2'>{review.data.userFirstName} {review.data.userLastName}</h3>
                <div className='flex items-center basis-2/12 p-2'>
                  <div className='flex items-center'>
                    {[...Array(review.data.rating)].map((star,index) => <img key={index} src={checkedIcon} alt='checkedIcon' />)}
                  </div>
                  <div className='flex items-center'>
                    {[...Array(5-review.data.rating)].map((star,index) => <img key={index} src={uncheckedIcon} alt='checkedIcon' />)}
                  </div>
                </div>
                <p className='text-text text-sm md:text-md basis-6/12 text-center md:text-left'>{review.data.content}</p>
                <p className='text-left text-sm md:text-lg text-text font-bold px-2 py-2 whitespace-nowrap'><span>{review.data.createdDate?.slice(0,10)}</span></p>
                <div className='basis-1/12 p-4'>
                  {review.data.visibility === 'true'?
                    <button onClick={() => handleVisibility(review.data.reviewUid)} disabled={isFetching}>
                      <img src={eye} alt='eye-Icon' />
                    </button>:
                    <button onClick={() => handleVisibility(review.data.reviewUid)} disabled={isFetching}>
                      <img src={eyeCrossed} alt='eyeCrossed-Icon'/>
                    </button>}
                </div>
              </div>)}
          </div>
          : <p>No Reviews yet</p>}
      </div>
    </div>
  )
}

export default DashboardReviews