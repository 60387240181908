import React from 'react'

const Blog = () => {
  return (
    <div className='px-4 md:px-20 pt-8 pb-4'>
      <div className='mb-4'>
        <div className='flex flex-col items-center justify-center'>
          <h2 className='mb-4 font-bold text-3xl text-center'>Software development Knowledge Base.</h2>
          <p className='text-text text-center'>Industry insights and practical solutions from tech experts to help you succeed.</p>
        </div>
        <div className='h-[40vh] flex items-center justify-center'>
          <p className='text-text'>No articles released yet.</p>
        </div>
      </div>
    </div>
  )
}

export default Blog