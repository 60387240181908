import React,{ useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Notification from '../Components/Notification'
import Loading from '../Components/Loading'
import { apiRequest } from '../Redux/ApiCalls'
import { passwordValidation } from '../Helpers/helpers'
import eye from '../Assets/Icons/eye.svg'
import eyeCrossed from '../Assets/Icons/eye-crossed.svg'
import logo from '../Assets/Images/second_logo.png'

const ResetPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [password,setPassword] = useState('')
  const [isLoading,setIsLoading] = useState(false)
  const [confirmPassword,setConfirmPassword] = useState('')
  const [failureMessage,setFailureMessage] = useState(null)
  const [successMessage,setSuccessMessage] = useState(null)
  const [showPassword, setShowPassword] = useState('password')
  const [showConfirmPassword, setShowConfirmPassword] = useState('password')
  const token = location.pathname.split('/')[3]

  const togglePassword = () => {
    showPassword==='password'?
      setShowPassword('text') :
      setShowPassword('password')
  }
  const toggleConfirmPassword = () => {
    showConfirmPassword==='password'?
      setShowConfirmPassword('text') :
      setShowConfirmPassword('password')
  }
  const headers = {
    token: token,
  }
  const handleSubmit = async(e) => {
    e.preventDefault()
    const isValidPassword = passwordValidation(password)
    setIsLoading(true)
    if(!password || !confirmPassword) {
      setFailureMessage('All fields are required')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else if (!isValidPassword) {
      setFailureMessage('Password should be at least 8 characters long and contain at least one special character, one number, and one uppercase letter.')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 10000)
    } else if (password !== confirmPassword) {
      setFailureMessage('Confirm password must be the same as password')
      setIsLoading(false)
      setTimeout(() => {
        setFailureMessage(null)
      }, 5000)
    } else {
      try {
        const res = await apiRequest.post('/users/reset/password',{ password }, { headers }, { timeout: 30000 })
        setIsLoading(false)
        setSuccessMessage(res.data.message)
        setTimeout(() => {
          setSuccessMessage(null)
        }, 5000)
        setPassword('')
        setConfirmPassword('')
        setTimeout(() => {
          navigate('/login')
        }, 5000)
      } catch (error) {
        if (error.response) {
          setFailureMessage(error.response?.data.message)
        } else {
          setFailureMessage(error.message)
        }
        setIsLoading(false)
        setTimeout(() => {
          setFailureMessage(null)
        }, 5000)
      }
    }
  }
  return (
    <div className='w-screen h-screen px-4 md:px-20 flex items-center justify-center'>
      <Notification success={successMessage} failure={failureMessage} color={successMessage? 'green' : 'red'} />
      <Link to='/'>
        <div className=' absolute top-12 left-20 w-[200px]'>
          <img src={logo} alt='Icon-logo' />
        </div>
      </Link>
      <div className="w-full">
        <h2 className='mb-8 text-4xl font-bold text-center'>Reset Password</h2>
        <div className="flex items-center justify-center">
          <form className='w-full md:w-2/3 lg:w-1/3 p-4 border rounded-lg' onSubmit={handleSubmit}>
            <div className='flex flex-col w-full my-2'>
              <label htmlFor="email" className='mb-1 text-lg font-bold'>Password*</label>
              <div className="flex items-center border rounded-lg">
                <input type={showPassword} name='password' value={password} placeholder='Password' maxLength='50'
                  className='p-2 w-full rounded-lg' onChange={(e) => setPassword(e.target.value)}/>
                <div data-testid='toggle-password-button' onClick={togglePassword} className='p-2 cursor-pointer'>
                  {showPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
                </div>
              </div>
            </div>
            <div className='flex flex-col w-full my-2'>
              <label htmlFor="email" className='mb-1 text-lg font-bold'>Confirm Password*</label>
              <div className="flex items-center border rounded-lg">
                <input type={showConfirmPassword} name='confirmPassword' value={confirmPassword} placeholder='Confirm Password' maxLength='50'
                  className='p-2 w-full rounded-lg' onChange={(e) => setConfirmPassword(e.target.value)} />
                <div data-testid='toggle-confirm-password-button' onClick={toggleConfirmPassword} className='p-2 cursor-pointer'>
                  {showConfirmPassword !== 'text' ? <img src={eye} alt='eye-Icon' /> : <img src={eyeCrossed} alt='eyeCrossed-Icon' />}
                </div>
              </div>
            </div>
            <button data-testid='Reset Password' type='submit' className='flex items-center justify-start px-4 py-2 text-md text-white bg-headers rounded-lg
            font-semibold shadow-sm'>
              {isLoading && <div className="w-full mr-2"><Loading color={'white'} /></div>}
              {isLoading? 'Reseting...' : 'Reset Password'}</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword